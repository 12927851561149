import { formatNumber } from '../../assets/utils';
import {
	cartItem,
	INITIAL_CART_STATE,
	moneyText,
	updateCart
} from './Cart.Constants';

const cartReducer = (state = INITIAL_CART_STATE, action) => {
	switch (action.type) {
		case cartItem.ADD_CART_ITEM:
			return {
				...state,
				items: addCartItem(state.items, action.payload, state.currency)
			};

		case cartItem.REMOVE_CART_ITEM:
			return {
				...state,
				items: removeCartItem(state.items, action.payload, state.currency)
			};

		case cartItem.CLEAR_CART_ITEM:
			return { ...state, items: clearCartItem(state.items, action.payload) };

		case cartItem.CLEAR_CART:
			return { ...state, items: [] };

		case updateCart.UPDATE_CURRENCY:
			return { ...state, currency: action.payload };

		case updateCart.UPDATE_SUBTOTAL:
			return {
				...state,
				subTotal: updateSubTotal(state.currency, state.items)
			};

		case updateCart.UPDATE_SHOPIFY_URL:
			return { ...state, shopifyUrl: action.payload };

		default:
			return state;
	}
};

const updateTotalPrice = (currency, cartItem) => ({
	...cartItem,
	totalPrice: `${moneyText[currency]}${formatNumber(
		cartItem.quantity * cartItem.price
	)}.00`
});

const addCartItem = (cartItems, cartItem, currency) => {
	const existingItem = cartItems.find(item => item.id === cartItem.id);

	if (!existingItem)
		return [...cartItems, updateTotalPrice(currency, cartItem)];

	return cartItems.map(item =>
		item.id === cartItem.id
			? updateTotalPrice(currency, {
					...cartItem,
					quantity: item.quantity + cartItem.quantity
			  })
			: item
	);
};

const removeCartItem = (cartItems, cartItem, currency) =>
	cartItems
		.map(item =>
			item.id === cartItem.id
				? updateTotalPrice(currency, {
						...cartItem,
						quantity: item.quantity - cartItem.quantity
				  })
				: item
		)
		.filter(item => item && item.quantity);

const clearCartItem = (cartItems, id) =>
	cartItems.filter(item => item.id !== id);

const updateSubTotal = (currency, cartItems) =>
	`${moneyText[currency]}${formatNumber(
		cartItems.reduce(
			(subTotal, item) => subTotal + item.quantity * item.price,
			0
		)
	)}`;

export default cartReducer;
