import './CurriculumCard.css';
const CurriculumCard = ({
	subjectHeading,
	quote,
	topics,
	bgColor,
	headingColor
}) => (
	<div
		className='curriclumSubjectCard'
		style={{ backgroundColor: `${bgColor}` }}
	>
		<h2 style={{ color: headingColor }}>{subjectHeading}</h2>
		<div className='cardQuoteContainer'>
			<span className='cardQuote'> {quote}</span>
		</div>
		<div className='curriculumPoints'>
			<ul>
				{topics.map((ulListItem, i) => (
					<li key={i}>{ulListItem}</li>
				))}
			</ul>
		</div>
	</div>
);

export default CurriculumCard;
