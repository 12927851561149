import ReactPlayer from 'react-player/lazy';
import '../VideoPlayer/VideoPlayer.scss';

const VideoPlayer = ({ url, width, height }) => {
	return (
		<div
			className='playerWrapper'
			style={{ height: '100%', maxWidth: width, width: '100%' }}>
			<div className='mini-wrapper'>
				<ReactPlayer
					className='react-player'
					url={url}
					controls={true}
					muted={true}
					playing={true}
					loop
					width='100%'
					height='100%'
					wrapper='div'
				/>
			</div>
		</div>
	);
};

export default VideoPlayer;
