import { DemoModel, GameModel, userData, Window } from './App.Constants';

export const openDemoModal = () => ({
	type: DemoModel.OPEN_DEMO_MODAL,
	payload: 'is-active'
});

export const closeDemoModal = () => ({
	type: DemoModel.CLOSE_DEMO_MODAL,
	payload: ''
});

export const openGameModal = data => ({
	type: GameModel.OPEN_GAME_MODAL,
	payload: {
		gameModal: 'is-active',
		gameModalData: data
	}
});

export const closeGameModal = () => ({
	type: GameModel.CLOSE_GAME_MODAL,
	payload: {
		gameModal: '',
		gameModalData: ''
	}
});

export const setWindowSize = size => ({
	type: Window.SET_WINDOW_SIZE,
	payload: size
});

export const setWindowTarget = target => ({
	type: Window.SET_WINDOW_TARGET,
	payload: target
});

export const userDataSuccess = data => ({
	type: userData.USER_DATA_SUCCESS,
	payload: data
});

export const userDataFailure = error => ({
	type: userData.USER_DATA_FAILURE,
	payload: error
});
