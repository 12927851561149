import { cartItem, updateCart } from './Cart.Constants';

export const addCartItem = item => ({
	type: cartItem.ADD_CART_ITEM,
	payload: item
});

export const removeCartItem = item => ({
	type: cartItem.REMOVE_CART_ITEM,
	payload: item
});

export const clearCartItem = id => ({
	type: cartItem.CLEAR_CART_ITEM,
	payload: id
});

export const clearCart = () => ({
	type: cartItem.CLEAR_CART
});

export const updateCurrency = currency => ({
	type: updateCart.UPDATE_CURRENCY,
	payload: currency
});

export const updateSubTotal = () => ({
	type: updateCart.UPDATE_SUBTOTAL
});

export const updateShopifyUrl = shopifyUrl => ({
	type: updateCart.UPDATE_SHOPIFY_URL,
	payload: shopifyUrl
});
