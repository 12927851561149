import {
	DemoModel,
	GameModel,
	INITIAL_APP_STATE,
	userData,
	Window
} from './App.Constants';

const appReducer = (state = INITIAL_APP_STATE, action) => {
	switch (action.type) {
		case DemoModel.OPEN_DEMO_MODAL:
			return { ...state, demoModal: action.payload };

		case DemoModel.CLOSE_DEMO_MODAL:
			return { ...state, demoModal: action.payload };

		case Window.SET_WINDOW_SIZE:
			return { ...state, window: { ...state.Window, ...action.payload } };

		case GameModel.OPEN_GAME_MODAL:
			return { ...state, ...action.payload };

		case GameModel.CLOSE_GAME_MODAL:
			return { ...state, ...action.payload };

		case userData.USER_DATA_SUCCESS:
			return { ...state, userData: action.payload };

		case userData.USER_DATA_FAILURE:
			return { ...state, error: action.payload };

		default:
			return { ...state };
	}
};

export default appReducer;
