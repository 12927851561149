import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FetchPlansPricing } from '../Shop/Shop.Constants';
import { userDataFailure, userDataSuccess } from './App.Actions';

function* userDataFetchStarter(data) {
	try {
		const keys = [
			'City',
			'State',
			'Zip_Code',
			'Country',
			'Screen_Width',
			'Screen_Height',
			'Device_Type',
			'OS',
			'country'
		];

		const userData = {};

		for (const key of keys) userData[key] = data.payload.userData[key];

		userData.Country_Code = userData.country;
		delete userData.country;

		yield put(userDataSuccess(userData));
	} catch (error) {
		yield put(userDataFailure(error));
	}
}

export function* userDataFetchStart() {
	yield takeLatest(
		FetchPlansPricing.FETCH_PLANS_PRICING_SUCCESS,
		userDataFetchStarter
	);
}

export default function* appSagas() {
	yield all([call(userDataFetchStart)]);
}
