import Slider from 'react-slick';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import './PillarsNew.sass';

function PillarsNew({
	textDivBGColor,
	mediaType,
	medias,
	points,
	buttonUrl,
	buttonColor,
	paragraph,
	buttonTextColor,
	buttonText,
	heading,
	headingColor,
	hasBtn
}) {
	const settings = {
		dots: false,
		infinite: true,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 2000,
		cssEase: 'linear',
		fade: true,
		responsive: [
			{
				breakpoint: 590,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			}
		]
	};

	const imgDivBgColor =
		window.innerWidth <= 768
			? `linear-gradient(180deg,#fff 60%,${textDivBGColor} 60%)`
			: 'transparent';

	const ulDisplay = !points.length ? 'none' : 'block';
	const mediaArr = medias.map((media, index) => {
		if (mediaType === 'video')
			return (
				<div className='m-auto h-full w-[20rem] md:w-[40rem]'>
					<VideoPlayer
						url={media}
						height='337px'
						width='560px'
						key={`pillarsNewVid${index}`}
					/>
				</div>
			);

		return <img src={media} alt='' key={`pillarsNewImg${index}`} />;
	});

	return (
		<>
			{heading && (
				<h3 className='mobileHeading' style={{ color: headingColor }}>
					{heading}
				</h3>
			)}

			<Slider
				className='pillarsSlider'
				{...settings}
				style={{ background: imgDivBgColor }}>
				{mediaArr}
			</Slider>
			<div className='pillarsTextContainer'>
				<div className='textCard' style={{ background: textDivBGColor }}>
					{<h3 style={{ color: headingColor }}>{heading}</h3>}

					<p>{paragraph}</p>
					<ul style={{ display: `${ulDisplay}` }}>
						{points.map((element, index) => (
							<li key={`pillars-new${index}`}>{element}</li>
						))}
					</ul>
					{hasBtn ? (
						<div style={{ width: '100%', height: 'auto' }}>
							<a className='h-auto w-max ' href={buttonUrl}>
								<button
									className='button'
									style={{
										backgroundColor: buttonColor,
										color: buttonTextColor,
										fontSize: '12px'
									}}>
									{buttonText}
								</button>
							</a>
						</div>
					) : (
						<span />
					)}
				</div>
			</div>
		</>
	);
}
export default PillarsNew;
