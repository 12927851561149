import styled from 'styled-components';
import { FlexCenter, fontFamily } from '../../../_constants.styled';

export const EnrollChildWrapper = styled.div`
	${FlexCenter}
	padding: 100px 7%;
	justify-content: space-evenly;
	align-items: center;
	margin-left: 10px;

	.left {
		.programme-wrapper {
			${FlexCenter}
			justify-content: space-between;
			flex-flow: row nowrap;
			margin: 20px 0px 45px;
			gap: 50px;
		}

		.enroll-button {
			max-width: 320px;
			height: 56px;
			background: #e2d868;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border: none;
			outline: none;
			font-family: 'Poppins';
			font-weight: 600;
			font-size: 20px;
			cursor: pointer;
		}
	}

	.fee-wrapper {
		width: fit-content;
		height: fit-content;
		background: #f8f8f8;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		${FlexCenter}
		justify-content: space-between;
		padding: 7px 25px;

		h4 {
			position: relative;
			top: ${({ country }) => (country === 'IN' ? '-10px' : '-20px')};
			font-family: ${fontFamily};
			color: #018093;
			line-height: 27px;
			margin-right: 20px;
			text-align: start;
			font-weight: 600;
		}

		.price {
			font-weight: 600;
		}

		span {
			color: #0000008a;
			font-weight: 600;

			p {
				font-size: 12px;
				font-weight: 600;
			}
		}

		.compare-price {
			text-decoration: ${({ diff }) => (diff ? 'line-through' : 'none')};
			color: #c8142a;
		}
	}

	@media (max-width: 940px) {
		flex-direction: column;
	}

	@media (max-width: 480px) {
		padding: 50px 0px 0px;
		justify-content: center;
		align-items: center;

		.left {
			${FlexCenter}
			justify-content: center;

			.programme-wrapper {
				flex-direction: column;
				justify-content: center;
			}
		}

		.right {
			${FlexCenter}
		}
	}
`;
