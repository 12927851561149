import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import appReducer from './App/App.Reducer';
import cartReducer from './Cart/Cart.Reducer';
import shopReducer from './Shop/Shop.Reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['cartReducer']
};

export const rootReducer = combineReducers({
	appReducer,
	shopReducer,
	cartReducer
});

export const persistCombined = persistReducer(persistConfig, rootReducer);
