import { FetchPlansPricing, INITIAL_SHOP_STATE } from './Shop.Constants';
import * as fx from 'money';

const shopReducer = (state = INITIAL_SHOP_STATE, action) => {
	switch (action.type) {
		case FetchPlansPricing.FETCH_PLANS_PRICING_SUCCESS:
			return {
				error: null,
				plansPricing: formatData(action.payload),
				isFetched: true
			};

		case FetchPlansPricing.FETCH_PLANS_PRICING_FAILURE:
			return { error: action.payload, plansPricing: null, isFetched: false };

		default:
			return state;
	}
};

function formatData(data) {
	const country =
		data.userData.country === 'IN' || data.userData.country === 'AE'
			? data.userData.country
			: 'INTL';
	const currency = data.userData.currency;

	fx.base = 'INR';
	fx.rates = {
		USD: 0.013,
		AED: 0.049
	};

	const obj = data.products.reduce(
		(prevVal, product) => {
			const programmes = ['360', 'explorer', 'phonics', 'numeracy'],
				ages = ['2-3', '3-4', '42', '36', '24'],
				months = ['3', '6', 'annual'];

			for (const programme of programmes)
				if (product.title.toLowerCase().includes(programme))
					for (const age of ages)
						if (product.title.includes(age)) {
							prevVal[programme][age] = {
								id: product.id,
								images: product.images.map(image => image.src),
								title: product.title
							};

							if (product.title.toLowerCase().includes('phonics')) {
								prevVal[programme][age].variant = product.variants
									.filter(item => item.title.includes(country.toLowerCase()))
									.map(item => ({
										id: item.id,
										image: item.image.src,
										price: fx.convert(Number(item.price), {
											from: 'INR',
											to: currency
										}),
										compareAtPrice: fx.convert(Number(item.compareAtPrice), {
											from: 'INR',
											to: currency
										})
									}))[0];
								continue;
							} else if (product.title.toLowerCase().includes('numeracy')) {
								prevVal[programme][age].variant = product.variants.map(
									item => ({
										id: item.id,
										image: item.image.src,
										price: fx.convert(item.price, {
											from: 'INR',
											to: currency
										}),
										compareAtPrice: fx.convert(item.compareAtPrice, {
											from: 'INR',
											to: currency
										})
									})
								)[0];
								continue;
							}
							for (const month of months)
								prevVal[programme][age][month] = product.variants
									.filter(
										item =>
											item.title.toLowerCase().includes(month) &&
											item.title.toLowerCase().includes(country.toLowerCase())
									)
									.map(item => ({
										id: item.id,
										image: item.image.src,
										price: fx.convert(item.price, {
											from: 'INR',
											to: currency
										}),
										compareAtPrice: fx.convert(item.compareAtPrice, {
											from: 'INR',
											to: currency
										})
									}))[0];
						}

			return JSON.parse(JSON.stringify(prevVal).replaceAll('annual', '12'));
		},
		{
			country,
			currency,
			360: {},
			explorer: {},
			phonics: {},
			numeracy: {},
			userData: data.userData
		}
	);

	return obj;
}

export default shopReducer;
