import styled from 'styled-components';

const StyledEnrollBtn = styled.button`
	width: 100%;
	max-width: 320px;
	height: 56px;
	background: #e2d868;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border: none;
	outline: none;
	font-family: 'Poppins';
	font-weight: 600;
	font-size: 20px;
	cursor: pointer;
	margin: 50px 15px;
	margin-right: 25px;
`;

const EnrollBtn = ({ children, handleClick }) => {
	const handleEnrollClick = () => {
		try {
			window.dataLayer.push({
				event: 'enroll_my_child_clicked'
			});
			window.fbq('track', 'Enroll Child - wonderLearn');
		} catch (error) {
			console.error(error);
		}
		handleClick();
	};

	return (
		<StyledEnrollBtn onClick={handleEnrollClick}>{children}</StyledEnrollBtn>
	);
};
export default EnrollBtn;
