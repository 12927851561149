import styled from 'styled-components';
const CCardContainer = styled.div`
	.media-wrapper {
		padding: 40px;
	}
	.slick-prev {
		color: blue;
	}
	.slide {
		margin: auto;
	}
	.slick-dots {
		li {
			margin: 0;
			&.slick-active {
				button {
					margin-top: 4px;
					transition: ease-in-out;
					height: 0px;
					background-color: white;
					border-radius: 6px;
					&::before {
						opacity: 0;
					}
				}
			}
		}
	}
	.magic-dots,
	.slick-dots {
		top: -5px;
	}
	.slick-active {
		.btndot2 {
			&:active {
				color: white;
			}
		}
	}
`;
export default CCardContainer;
