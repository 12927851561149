import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	closeDemoModal,
	closeGameModal,
	openDemoModal,
	openGameModal
} from '../ReduxStore/App/App.Actions';

export const useModalToggler = () => {
	const modalState = useSelector(state => state.appReducer.demoModal);

	const dispatch = useDispatch();

	return [
		() => dispatch(!modalState ? openDemoModal() : closeDemoModal()),
		modalState
	];
};

export const useGameCloser = () => {
	const [gameState, gameData] = useSelector(state => [
		state.appReducer.gameModal,
		state.appReducer.gameModalData
	]);

	const dispatch = useDispatch();

	return [() => dispatch(closeGameModal()), gameState, gameData];
};

export const useGameOpener = () => {
	const dispatch = useDispatch();

	return data => dispatch(openGameModal(data));
};

/**
 *
 * @returns https://d37772fpgg0hig.cloudfront.net/assets/images/{imageName}
 */
export const imgUrl = imgName =>
	`https://d37772fpgg0hig.cloudfront.net/assets/images/${imgName}`;

export const videoUrl = videoName =>
	`https://d37772fpgg0hig.cloudfront.net/assets/Videos/${videoName}`;

export const gamesThumbUrl = name =>
	`https://d3dyspupjk4pz7.cloudfront.net/thumbnails/${name}`;

export const formatNumber = number =>
	Math.round(number)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const useLogger = (...args) => {
	useEffect(() => {
		console.log(...args);
	}, [args]);
};

export const newSiteLink = endUrl => `https://thewonderlearn.com/${endUrl}`;
