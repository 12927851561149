import styled from 'styled-components';
import { FlexCenter, primary, secondary } from '../../../../_constants.styled';

export const StepSwitchWrapper = styled.div`
	width: fit-content;
	height: 72px;
	margin: 20px 0px 45px;
	padding: 0px 20px;
	background: ${secondary};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	${FlexCenter};
	justify-content: flex-start;

	.toggler {
		position: absolute;
		width: 144px;
		height: 40px;
		background: ${primary};
		box-shadow: 0px 4px 4px rgba(80, 56, 56, 0.25);
		border-radius: 4px;
		-webkit-transition: all 0.2s ease-in-out 0s;
		transition: all 0.2s ease-in-out 0s;
		transform: translateX(${({ index }) => `${index || 0}px`});
	}

	.active {
		color: white;
	}

	.button {
		width: 144px;
		height: 40px;
		background: transparent;
		box-shadow: 0px 4px 4px transparent;
		border-radius: 4px;
		z-index: 1;
		transition: 0.4s;
		${FlexCenter}
		outline: none;
		border: none;
		font-family: 'Poppins';
		font-weight: 500;
		cursor: pointer;
		&:focus {
			color: white;
		}
	}

	@media (max-width: 580px) {
		.button {
			width: 100px;
		}

		.toggler {
			width: 100px;
			transform: translateX(${({ index }) => `${index || 0}px`});
		}
	}
`;
