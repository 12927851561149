import Saga from 'redux-saga';
import Logger from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import rootSaga from './Saga.Root';
import { persistCombined } from './Root.Reducer';

const sagaMiddleware = Saga();

const middlewares = [sagaMiddleware];
if (process.env.NODE_ENV === 'development') middlewares.push(Logger);

export const Store = createStore(
	persistCombined,
	applyMiddleware(...middlewares)
);

sagaMiddleware.run(rootSaga);

export const PersistStore = persistStore(Store);
