import { useContext } from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { ScrollIntoViewContext } from '../../../PlansAndPricing/PlansAndPricing';
import { ProgrammeChildWrapper } from './ProgrammeChild.styled';

const ProgrammeChild = ({
	title,
	keypoints,
	titleIcon,
	isActive,
	toggleActive,
	text
}) => {
	const handleClick = useContext(ScrollIntoViewContext);

	return (
		<ProgrammeChildWrapper isActive={isActive}>
			<div onClick={toggleActive}>
				<div className='top'>
					<p className='flex justify-center items-center gap-1.5'>
						{titleIcon} {'  '} {title}
					</p>
				</div>
				<p className='title'>{text}</p>
				<ul>
					{keypoints.map((e, i) => (
						<li key={`${i}-key-points`}>
							<GiCheckMark color='#00A344' /> {'   '} {e}
						</li>
					))}
				</ul>
			</div>
			<span onClick={handleClick}>Know More</span>
		</ProgrammeChildWrapper>
	);
};

export default ProgrammeChild;
