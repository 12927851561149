import '../UspGrid/UspGrid.sass';
import SectionHeader from '../SectionHeader/SectionHeader';

export default function UspGrid(props) {
	return (
		<>
			<div className='uspGridSection' style={{ padding: '50px 20px' }}>
				<div className='uspGridHeader'>
					<SectionHeader itemName={props.total['header']} />
				</div>
				<div
					className='uspGridContainer'
					style={{
						gridTemplateColumns: `repeat(auto-fit, minmax(${props.elementMinSize},${props.elementMaxSize}))`
					}}
				>
					{props.content.map((element, index) => (
						<div
							className='gridElement'
							style={{ display: 'flex' }}
							key={index}
						>
							<div className='imageDiv'>
								<img src={element['img-url']} alt='' />
							</div>
							<div className='textDiv'>
								<h4>{element['text']}</h4>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}
