import { useEffect } from 'react';
import { useModalToggler } from '../../../assets/utils';
import './FooterSticky.sass';

export default function FooterSticky() {
	const [showModalToggle] = useModalToggler();

	useEffect(() => {
		window.addEventListener('scroll', addSticky);
	}, []);

	const addSticky = () => {
		if (window.innerWidth < 1025) {
			document.getElementById('stickyFooter').classList.add('fixedActive');
		}
	};

	return (
		<div className='stickyFooter' id='stickyFooter'>
			<div>
				<button className='button' onClick={showModalToggle}>
					Book a free demo
				</button>
			</div>
		</div>
	);
}
