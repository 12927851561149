import Pillars from '../KeyPillars/PillarsNew/PillarsNew';
import SectionHeader from '../../Component/SectionHeader/SectionHeader';
import '../KeyPillars/KeyPillar.sass';

function KeyPillar({ pillarsInfoContainer, heading, bgColor = 'white' }) {
	return (
		<div
			className='keyPillarContainer'
			style={{ display: 'flex', backgroundColor: bgColor }}
		>
			<SectionHeader itemName={heading} />
			{pillarsInfoContainer.map((pillar, index) => (
				<div
					key={`keyPillarDiv${index}`}
					className='pillarsWrapper'
					style={{
						display: 'flex',
						maxHeight: '600px',
						margin: '25px 0',
						flexDirection: `${!(index % 2) ? 'row' : 'row-reverse'}`
					}}
				>
					<Pillars
						key={`keyPillar${index}`}
						num={index}
						hasBtn={pillar.hasBtn ?? true}
						heading={pillar['pillar-heading']}
						paragraph={pillar['pillar-paragraph']}
						points={pillar['pillar-points']}
						buttonText={pillar['button-text']}
						buttonUrl={pillar['button-url']}
						direction={!(index % 2) ? 'row' : 'row-reverse'}
						imgAlign={!(index % 2) ? 'left' : 'right'}
						textDivDirec={!(index % 2) ? 'flex-end' : 'flex-start'}
						textDivBGColor={pillar['textDivBGColor']}
						medias={pillar['media']}
						mediaType={pillar['media-type']}
						buttonColor={pillar['button-color']}
						buttonTextColor={pillar['button-text-color']}
						headingColor={pillar['pillar-heading-color']}
					/>
				</div>
			))}
		</div>
	);
}
export default KeyPillar;
