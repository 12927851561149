import Button from './Button';
import { StepSwitchWrapper } from './StepSwitch.styled';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const StepSwitch = ({ Val, handleClick, selectedValue, label }) => {
	const [index, setIndex] = useState(0);

	const width = useSelector(state => state.appReducer.window.width);

	useEffect(() => {
		const i = Val.indexOf(selectedValue);
		setIndex(i * (width > 480 ? 144 : 100));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue]);

	return (
		<StepSwitchWrapper index={index}>
			{Val.map((e, i) => (
				<Button
					key={`${label}-${i}_stepSwitch`}
					active={e === selectedValue}
					interval={e}
					label={label}
					funcTocall={handleClick}
					selectedValue={selectedValue}
				/>
			))}
			<div className='toggler' />
		</StepSwitchWrapper>
	);
};

export default StepSwitch;
