import './TestimonialCard.scss';

const TestimonialCard = ({ background, message, writter }) => {
	return (
		<div className='TestimonialCard-container'>
			<div className='text-container'>
				<h1>"</h1>
				<p>{message}</p>
				<p className='writter'>
					{'- '}
					{writter}
				</p>
			</div>
			<div
				className='TestimonialCard-wrapper'
				style={{
					background: `url(${background}) center center/cover`
				}}
			></div>
		</div>
	);
};

export default TestimonialCard;
