import { FetchPlansPricing } from './Shop.Constants';

export const FetchPlansPricingStart = () => ({
	type: FetchPlansPricing.FETCH_PLANS_PRICING_START
});

export const FetchPlansPricingSuccess = data => ({
	type: FetchPlansPricing.FETCH_PLANS_PRICING_SUCCESS,
	payload: data
});

export const FetchPlansPricingFailure = error => ({
	type: FetchPlansPricing.FETCH_PLANS_PRICING_FAILURE,
	payload: error
});
