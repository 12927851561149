import { gamesThumbUrl, imgUrl, newSiteLink, videoUrl } from '../assets/utils';

export const learningCards = {
	heading: 'What makes our learning toys Unique?',
	headingColor: '#1E8394',
	cardsData: [
		{
			img: imgUrl('toys-1.webp'),
			alt: '',
			heading: 'Sustainable',
			text: 'Made with high-quality wooden fabric materials that will last for years'
		},
		{
			img: imgUrl('toys-2.webp'),
			alt: '',
			heading: 'Reusable',
			text: 'Not at all a use-and-throw thing; totally eco-friendly'
		},
		{
			img: imgUrl('toys-3.webp'),
			alt: '',
			heading: 'Integration',
			text: 'Blend the toys with several themes and topics.'
		},
		{
			img: imgUrl('toys-4.webp'),
			alt: '',
			heading: 'Creative',
			text: 'Promotes lateral thinking and make problem-solving an easy & fun activity.'
		}
	]
};

export const gamesCards = {
	bgColor: '#f6f7f9',
	cardsData: [
		{
			img: gamesThumbUrl('ukg/numeracy/apple-tree-roll'),
			alt: '',
			heading: 'Apple Tree Roll Game',
			text: 'Roll the dice, match the number. Learn identification of numbers',
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/apple-tree-roll-game-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/apple-tree-roll-game-mobile/story.html'
		},
		{
			img: gamesThumbUrl('ukg/phonics/fun-with-rhymes'),
			alt: '',
			heading: 'Rhyming words game',
			text: "Let's Learn together with wonderLearn",
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M3L3-rhyming+words-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M3L3-rhyming-words-mobile/story.html'
		},
		{
			img: gamesThumbUrl('toddler/month-3/identify-the-missing-shape-number'),
			alt: '',
			heading: 'What number am I',
			text: "Let's Learn together with wonderLearn",
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M3N1-What-Number-Am-I-Desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M3N1-What-Number-Am-I-mpbile/story.html'
		},
		{
			img: gamesThumbUrl('ukg/numeracy/repeat-the-pattern'),
			alt: '',
			heading: 'Repeat the pattern',
			text: "Let's Learn together with wonderLearn",
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M4-N3-Repeat-the-pattern_22-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M4-N3-Repeat-the-pattern_22-mobile/story.html'
		},
		{
			img: gamesThumbUrl('lkg/month-4/seasons-and-me'),
			alt: '',
			heading: 'Seasons',
			text: 'Do you know how many seasons we have ? Yes you do! Then you also know what represents every season? Lets find out by playing this super fun game!',
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M4T1-SEASONS-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M4T1-SEASONS-mobile/story.html'
		},
		{
			img: gamesThumbUrl('lkg/month-5/form-the-words'),
			alt: '',
			heading: 'Form the word',
			text: 'Can you form the words? Blend, listen to the sound and form words',
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5L1-FORM-THE-WORD-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5L1-FORM-THE-WORD-mobile/story.html'
		},
		{
			img: gamesThumbUrl('lkg/month-5/place-the-letters'),
			alt: '',
			heading: 'Place the letters',
			text: 'Can you place the letters on the Apple Tree? Recognize and match alphabets',
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5L3-PLACE-THE-LETTERS-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5L3-PLACE-THE-LETTERS-mobile/story.html'
		},
		{
			img: gamesThumbUrl('lkg/month-5/create-a-shape'),
			alt: '',
			heading: 'Create a shape',
			text: 'Match ths small shapes to create a boat, house and lots more. Build logical thinking and shape recognition',
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5M1-CREATE-A-SHAPE-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5M1-CREATE-A-SHAPE-mobile/story.html'
		},
		{
			img: gamesThumbUrl('lkg/month-5/make-the-pizza'),
			alt: '',
			heading: 'Make your own pizza',
			text: 'Learn and practice sequence identification',
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5-N1-make-your-own-pizza-v1-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M5-N1-make-your-own-pizza-v1-mobile/story.html'
		},
		{
			img: gamesThumbUrl('ukg/numeracy/can-you-make-a-number'),
			alt: '',
			heading: 'Can you make a number',
			text: 'Do you know there a more than one way to make a Number. 8+2 is 10. 6+4 is also 10. 3+7 is also 10 and so is 5+5. Can you guess different ways to make more numbers? Come lets try.',
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M4-N2-CAN-YOU-MAKE-NUMBER-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M4-N2-CAN-YOU-MAKE-NUMBER-mobile/story.html'
		},
		{
			img: gamesThumbUrl('lkg/month-6/odd-one-out'),
			alt: '',
			heading: 'Odd one out',
			text: "Can you find out what doesn't quite fit?",
			stars: 5,
			desktopLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M6I1-Identify-odd-one-scorm-desktop/story.html',
			mobileLink:
				'https://d29jkgztppfjmt.cloudfront.net/wonderlearn_game/K1M6I1-Identify-odd-one-scorm-mobile/story.html'
		}
	]
};

export const learningToysBanner = {
	'banner-title-parts': ['wonder', 'Learn'],
	'banner-subHeading':
		'The most incredibly crafted learning toys for preschoolers',
	'banner-points': [
		'Play-first learning with 500+ toys and activities, made of high-quality wooden fabric material along live classes and digital educational games'
	],
	'banner-cta': {
		text: 'Book a free demo',
		url: '/plans-and-pricing'
	},
	'banner-slider-images': [imgUrl('Home/home-banner-1.webp')]
};

export const homePageBanner = {
	'banner-title-parts': ['wonder', 'Learn'],
	'banner-subHeading':
		'The Most Innovative & Complete Early Learning Experience',
	'banner-points': [
		'Play-led learning for 2-6 years olds',
		'Interactive & super engaging',
		'Accessible anytime and anywhere'
	],
	'banner-cta': {
		text: 'Book a free demo',
		url: '/plans-and-pricing'
	},
	'banner-backgroundImg-url': imgUrl('Home/Background.webp'),
	'banner-slider-images': [
		imgUrl('Home/home-banner-1.webp'),
		imgUrl('Home/home-banner-2.webp')
	]
};

export const ExplorerPageBanner = {
	'banner-title-parts': ['wonder', 'Learn'],

	'banner-subHeading':
		'Placing the Complete Power of Teaching in Parents’ Hands',
	'banner-points': [
		'No more spending time researching learning activities for your kids! We’ve got you covered'
	],
	'banner-cta': {
		text: 'Book Now',
		url: ''
	},
	'banner-backgroundImg-url': imgUrl('Background.webp'),
	'banner-slider-images': [imgUrl('banner%202.webp')]
};

export const marketingPageBanner = {
	'banner-title-parts': ['wonder', 'Learn'],

	'banner-subHeading': 'Communication Skills Program for Kids',
	'banner-points': [
		'Let your little ones sharpen their communication skills through a structured phonics based learning program'
	],
	'banner-cta': {
		text: 'Book a free demo',
		url: '/plans-and-pricing'
	},
	'banner-backgroundImg-url': imgUrl('Background.webp'),
	'banner-slider-images': [imgUrl('banner%202.webp')]
};

export const onlinepreschoolPageBanner = {
	'banner-title-parts': ['wonder', 'Learn'],

	'banner-subHeading': 'Online Preschool Classes for 2-6 years old',
	'banner-points': ['Complete online preschool experience, just a click away'],
	'banner-cta': {
		text: 'Book a Free Trial Class',
		url: 'https://calendly.com/d/cd4-m5r-pgj/wonderlearn-trial-class'
	},
	'banner-backgroundImg-url': imgUrl('Background.webp'),
	'banner-slider-images': [imgUrl('banner%202.webp')]
};

export const socialPageBanner = {
	'banner-title-parts': ['wonder', 'Learn'],

	'banner-subHeading':
		'Place to Enhance Social & Emotional Learning among Kids',
	'banner-points': [
		'Researchers, educators & parents agree that social and emotional learning (SEL) matter for success in life. And we give 200% focus on them'
	],
	'banner-cta': {
		text: 'Book a free demo',
		url: '/plans-and-pricing'
	},
	'banner-backgroundImg-url': imgUrl('Background.webp'),
	'banner-slider-images': [imgUrl('Social-skills/banner.webp')]
};

export const cognitivePageBanner = {
	'banner-title-parts': ['wonder', 'Learn'],

	'banner-subHeading':
		'The Comprehensive Learning Program for Cognitive Development in Kids',
	'banner-points': [
		'Through our curriculum and especially designed toys, we work with your little ones to hone their problem solving and cognitive skills muscle'
	],
	'banner-cta': {
		text: 'Book a free demo',
		url: '/plans-and-pricing'
	},
	'banner-backgroundImg-url': imgUrl('Background.webp'),
	'banner-slider-images': [imgUrl('Cognitive%20skills/banner.webp')]
};

export const artPageBanner = {
	'banner-title-parts': ['wonder', 'Learn'],

	'banner-subHeading': 'The Best-In-Class Medium to Express and Learn',
	'banner-points': [
		"Spark the imagination, creativity and expression of your child through wonderLearn's focus on art skills"
	],
	'banner-cta': {
		text: 'Book a free demo',
		url: '/plans-and-pricing'
	},
	'banner-backgroundImg-url': imgUrl('Background.webp'),
	'banner-slider-images': [imgUrl('Art%20skills/Banner.webp')]
};

export const keyPillarsInfo = [
	{
		'pillar-heading': 'Learning Toys',
		'pillar-paragraph':
			'Nurture your child’s creativity, imagination by making them touch, feel, play and learn, with the toys that are absolute fun.',
		'pillar-points': [
			'Thoughtfully designed 400+ learning toys & activities',
			'Easy-to-use, self-explanatory and highly engaging',
			'Strengthen parent-child bond'
		],
		'pillar-points-align': 'left',
		textDivBGColor: 'rgb(128 210 224 / 57%)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'learning toys',
		media: [imgUrl('logo.png'), imgUrl('pricingUspImgOne.png')],
		'media-type': 'image'
	},
	{
		'pillar-heading': 'Learning Toys',
		'pillar-paragraph':
			'Nurture your child’s creativity, imagination by making them touch, feel, play and learn, with the toys that are absolute fun.',
		'pillar-points': [
			'Thoughtfully designed 400+ learning toys & activities',
			'Easy-to-use, self-explanatory and highly engaging',
			'Strengthen parent-child bond'
		],
		textDivBGColor: 'rgb(238 214 225 / 100%)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(238 214 225)',
		'button-text': 'lorez ipsum'
	},
	{
		'pillar-heading': 'Learning Toys',
		'pillar-paragraph':
			'Nurture your child’s creativity, imagination by making them touch, feel, play and learn, with the toys that are absolute fun.',
		'pillar-points': [
			'Thoughtfully designed 400+ learning toys & activities',
			'Easy-to-use, self-explanatory and highly engaging',
			'Strengthen parent-child bond'
		],
		textDivBGColor: 'rgb(236 229 149/ 47%)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(237 238 201)',
		'button-text': 'learning toys'
	}
];

export const monthlyKitInfo = {
	sectionHeader: "What's in my monthly kit?",
	contents: [
		{
			img: imgUrl('monthly-kit-1.webp'),
			heading: 'Toys',
			texts:
				'3-4 learning toys in every monthly kit that are in sync with learning curriculum'
		},
		{
			img: imgUrl('monthly-kit-3.webp'),
			heading: 'Books',
			texts:
				'Age appropriate, picture enabled books to develop them into life long readers'
		},
		{
			img: imgUrl('monthly-kit-2.webp'),
			heading: 'Activity Sheets',
			texts: 'To reinforce the concepts being learnt.'
		},
		{
			img: imgUrl('monthly-kit-4.webp'),
			heading: 'Art & Craft',
			texts:
				'To develop their fine and gross motor skills, expression and creativity.'
		}
	]
};

export const learningToysUnique = {
	sectionHeader: 'What makes our learning toys Unique?',
	contents: [
		{
			img: imgUrl('pricingUspImgOne.png'),
			heading: 'Sustainable',
			texts:
				'Made with high-quality wooden fabric materials that will last for years'
		},
		{
			img: imgUrl('pricingUspImgTwo.png'),
			heading: 'Reusable',
			texts:
				'Made with high-quality wooden fabric materials that will last for years'
		},
		{
			img: imgUrl('pricingUspImgThree.png'),
			heading: 'Integration',
			texts:
				'Made with high-quality wooden fabric materials that will last for years'
		}
	]
};

export const curriculumInfo = {
	'2-3': {
		heading: 'What will my child learn ?',
		subHeading: 'Toddler: I See, I Imitate, I learn',
		age: '2-3',
		subjectDetails: [
			{
				subjectHeading: 'Cognition',
				quote:
					'“I look at the world around me, and try to understand what I see”',
				topics: [
					'Colors | Shapes | Sorting | Lacing | Matching',
					'Color Mixing | Float & Sink',
					'Diet | Healthy Eating Habits | Basic Nutrition',
					'Understanding Emotions | Quantity'
				],
				bgColor: 'rgb(176, 225, 234)',
				headingColor: 'rgb(30 131 148)'
			},
			{
				subjectHeading: 'Communication',
				quote:
					'“I hear many things - sounds and voices. I try and make some of my own”',
				topics: [
					'Environment Sounds | Rhymes',
					'Exploring the world of sounds',
					'Alliteration, Word Box',
					'Understanding Emotions | Quantity'
				],
				bgColor: 'rgb(234 234 190)',
				headingColor: 'rgb(163 142 117)'
			},
			{
				subjectHeading: 'Social',
				quote:
					"“I see things around me - some look like me, some don't. I'm still learning”",
				topics: [
					'Face | Basic Body Part Awareness',
					'Fruits | Vegetables | Transportation',
					'Seasons',
					'Sun | Moons | Stars | Planets | Universe'
				],
				bgColor: 'rgb(235 208 225)',
				headingColor: 'rgb(165 30 122)'
			},
			{
				subjectHeading: 'Art',
				quote:
					'“Getting messy is fun. I feel new things and see what all I can do with them.”',
				topics: [
					'Fun with Play Dough',
					'Color mixing, float & sink',
					'Body Percussion',
					'Animal Puppets'
				],
				bgColor: 'rgb(176, 225, 234)',
				headingColor: 'rgb(30 131 148)'
			}
		]
	},
	'3-4': {
		heading: 'What will my child learn ?',
		subHeading: 'The Learning Adventure - K1: My World is my Playground',
		age: '3-4',
		subjectDetails: [
			{
				subjectHeading: 'Literacy',
				quote: '“I now can talk and understand words. There is so much to do.”',
				topics: [
					'Phonics - SATPIN, CKEH, RMD | Sound Matching',
					'Phonics - GOULFB, Letter Sounds | Sound Matching',
					'Alternate Vowel Sounds | Tricky Sounds',
					'Rhyming Words | Rhymes | Sound Groups'
				],
				bgColor: 'rgb(176, 225, 234)',
				headingColor: 'rgb(30 131 148)'
			},
			{
				subjectHeading: 'Numeracy',
				quote: '“The number world is really vast! How much more can I learn”',
				topics: [
					'Numbers 1 - 30 | Sequencing | Match and Order',
					'Spatial Positions | Measurement | Quantity',
					'Basics of Addition and Subtraction | Measurement',
					'Backward Counting | Time | Patterns'
				],
				bgColor: 'rgb(234 234 190)',
				headingColor: 'rgb(163 142 117)'
			},
			{
				subjectHeading: 'Social',
				quote: "“It's time to explore the whole world around me. Wow.”",
				topics: [
					'Me, My Family and Community',
					'Seasons | World Festivals | Domestic Animals',
					'Farm Animals | Sea Creatures | Pets | Birds | Bugs | Planet',
					"The 3 R's | Story Telling | Rhymes"
				],
				bgColor: 'rgb(235 208 225)',
				headingColor: 'rgb(165 30 122)'
			},
			{
				subjectHeading: 'Arts',
				quote:
					'“There is so much in the world around me, I need to understand it all!”',
				topics: [
					'Sponge Painting | Paper Shapes | Roller & Scraper Painting',
					'Stamp Art | Warli Art',
					'Folk Art | Free Drawing | Clay Modelling',
					"Bubble Painting | The 3 R's - Reduce, Reuse, Recycle"
				],
				bgColor: 'rgb(176, 225, 234)',
				headingColor: 'rgb(30 131 148)'
			}
		]
	}
};

export const footerMenu = [
	{
		menuHeading: 'Explore wonderLearn',
		menuContent: [
			{
				contentType: 'anchor',
				url: newSiteLink('plans-and-pricing'),
				content: 'Plans and pricing'
			},
			{
				contentType: 'link',
				url: '/cognitive-skills-for-kids',
				content: 'Cognitive Skill'
			},
			{
				contentType: 'link',
				url: '/art-skills-for-kids',
				content: 'Art Skill'
			},
			{
				contentType: 'link',
				url: '/social-skills-for-kids',
				content: 'Social Skill'
			},
			{
				contentType: 'anchor',
				url: newSiteLink('communication-skills-for-kids'),
				content: 'Phonics program'
			},
			{
				contentType: 'link',
				url: '/cognitive-skills-for-kids',
				content: 'Numeracy program'
			}
		]
	},
	{
		menuHeading: 'Resources',
		menuContent: [
			{
				contentType: 'link',
				url: '/about-us',
				content: 'About us'
			},
			{
				contentType: 'link',
				url: '/faq',
				content: 'FAQ'
			},
			{
				contentType: 'link',
				url: '/blogs',
				content: 'Blogs'
			},
			{
				contentType: 'link',
				url: '/terms-of-service',
				content: 'Terms of service'
			},
			{
				contentType: 'link',
				url: '/privacy-policy',
				content: 'Privacy policy'
			},
			{
				contentType: 'link',
				url: '/return-and-cancellation',
				content: 'Refund and cancellation policy'
			}
		]
	},
	{
		menuHeading: 'Hear from us',
		menuContent: [
			{
				contentType: 'anchor',
				url: 'tel:+917795595880',
				content: 'Phone: +91 8951140320'
			},
			{
				contentType: 'anchor',
				url: 'mailto:support@wonderhood.in',
				content: 'Email: support@wonderhood.in'
			},
			{
				contentType: 'demo-modal',
				url: 'https://wonderhood.in/pages/new-book-demo-page',
				content: 'Book a free demo'
			},
			{
				contentType: 'calendly',
				url: 'https://calendly.com/d/cd4-m5r-pgj/wonderlearn-trial-class?primary_color=4bafc1',
				content: 'Book a free demo class'
			}
		]
	}
];

export const sliderInfo = {
	'slider-heading': 'What Our Happy Customers Say?',
	slider: [
		{
			'slider-text': '',
			'slider-img':
				'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/test-1.webp?v=1633073660'
		},
		{
			'slider-text': '',
			'slider-img':
				'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/test-2.webp?v=1633341358'
		},
		{
			'slider-text': '',
			'slider-img':
				'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/test-3.webp?v=1633341358'
		},
		{
			'slider-text': '',
			'slider-img':
				'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/test-4.webp?v=1633341358'
		},
		{
			'slider-text': '',
			'slider-img':
				'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/test-5.webp?v=1633341358'
		}
	]
};
export const ospGrid = {
	'online-usp-info': {
		header:
			'Welcome to the Parents’ Most Recommended Online Preschool Classes In Noida Delhi India',
		content: [
			{
				'img-url': imgUrl('communications/1.webp'),
				text: 'Adaptive Learning'
			},
			{
				'img-url': imgUrl('communications/2.webp'),
				text: 'Daily Activity sheets'
			},
			{
				'img-url': imgUrl('communications/3.webp'),
				text: 'Interactive Games'
			},
			{
				'img-url': imgUrl('communications/4.webp'),
				text: 'Read-Along Stories'
			},
			{
				'img-url': imgUrl('communications/5.webp'),
				text: 'Audio  Visuals'
			},
			{
				'img-url': imgUrl('communications/6.webp'),
				text: 'DIY Activities'
			}
		]
	},
	'marketing-usp-info-2': {
		header: 'Why wonderLearn Online Preschool Classes',
		content: [
			{
				'img-url': imgUrl('icon%2011.webp'),
				text: 'Educate Through Comprehensive Learning'
			},
			{
				'img-url': imgUrl('icon%208.webp'),
				text: 'Holistic Environment For 360 Development'
			},
			{
				'img-url': imgUrl('icon%209.webp'),
				text: 'Interactive Sessions With Innovative Learning Kits'
			},
			{
				'img-url': imgUrl('icon%2010.webp'),
				text: 'Learning by playing’ Based Live Sessions'
			}
		]
	}
};

export const uspGrid = {
	'home-usp-info': {
		header: 'Think Unique, Do Innovative with wonderLearn',
		content: [
			{
				'img-url': imgUrl('Home/icon_1.webp'),
				text: 'Age-appropriate and super engaging learning toys & activities'
			},
			{
				'img-url': imgUrl('Home/icon_2.webp'),
				text: 'Well structured & outcome oriented learning curriculum.'
			},
			{
				'img-url': imgUrl('Home/icon_3.webp'),
				text: 'Unlimited access to meaningful digital content.'
			},
			{
				'img-url': imgUrl('Home/icon_4.webp'),
				text: 'Foster motor skills, cognitive development, social and communication skills.'
			},
			{
				'img-url': imgUrl('Home/icon_5.webp'),
				text: 'Live interactive online classes with a healthy student-to-teacher ratio.'
			},
			{
				'img-url': imgUrl('Home/icon_6.webp'),
				text: 'Repetitive, integrated and reusable learning methods.'
			}
		]
	},
	'learning-usp-info': {
		header: 'What makes our learning toys Unique',
		content: [
			{
				'img-url': imgUrl('Home/icon_1.webp'),
				text: 'Age-appropriate and super engaging learning toys & activities'
			},
			{
				'img-url': imgUrl('Home/icon_2.webp'),
				text: 'Well structured & outcome oriented learning curriculum.'
			},
			{
				'img-url': imgUrl('Home/icon_3.webp'),
				text: 'Unlimited access to meaningful digital content.'
			},
			{
				'img-url': imgUrl('Home/icon_4.webp'),
				text: 'Foster motor skills, cognitive development, social and communication skills.'
			},
			{
				'img-url': imgUrl('Home/icon_5.webp'),
				text: 'Live interactive online classes with a healthy student-to-teacher ratio.'
			},
			{
				'img-url': imgUrl('Home/icon_6.webp'),
				text: 'Repetitive, integrated and reusable learning methods.'
			}
		]
	},
	'explorer-usp-info': {
		header: 'What’s in there for you',
		content: [
			{
				'img-url': imgUrl('communications/1.webp'),
				text: 'Get well-researched learning toys integrated with digital content'
			},
			{
				'img-url': imgUrl('communications/2.webp'),
				text: 'Learn at your own pace and schedule'
			},
			{
				'img-url': imgUrl('communications/3.webp'),
				text: 'Designed as per proven curriculum'
			},
			{
				'img-url': imgUrl('communications/4.webp'),
				text: 'Monthly box of educational toys delivered at home'
			},
			{
				'img-url': imgUrl('communications/5.webp'),
				text: 'Unlimited recorded classes & content on learning app'
			},
			{
				'img-url': imgUrl('communications/6.webp'),
				text: 'Access to phonics games on learning app'
			}
		]
	},
	'marketing-usp-info': {
		header: 'Welcome to the Most Comprehensive Communication Program for Kids',
		content: [
			{
				'img-url': imgUrl('communications/1.webp'),
				text: 'Outcome based program with plan for every class'
			},
			{
				'img-url': imgUrl('communications/2.webp'),
				text: 'Phonics based English language learning curriculum'
			},
			{
				'img-url': imgUrl('communications/3.webp'),
				text: 'Personalized Interactive classes to help them speak and practice'
			},
			{
				'img-url': imgUrl('communications/4.webp'),
				text: ' Engaging toys & activities'
			},
			{
				'img-url': imgUrl('communications/5.webp'),
				text: 'Age appropriate books to encourage self reading'
			},
			{
				'img-url': imgUrl('communications/6.webp'),
				text: 'Access to phonics games on learning app'
			}
		]
	},
	'marketing-usp-info-2': {
		header: 'Transform your kid into a confident speaker',
		content: [
			{
				'img-url': imgUrl('icon%2011.webp'),
				text: 'Develop word formation abilities'
			},
			{
				'img-url': imgUrl('icon%208.webp'),
				text: 'Enhance reading skills'
			},
			{
				'img-url': imgUrl('icon%209.webp'),
				text: 'Gain confidence in fluency'
			},
			{
				'img-url': imgUrl('icon%2010.webp'),
				text: 'Shape overall personality'
			}
		]
	},
	'social-usp-info': {
		header: 'How wonderlearn helps with social & emotional learning',
		content: [
			{
				'img-url': imgUrl('Social-skills/icon%201.webp'),
				text: 'Awareness of self - my body, my body parts'
			},
			{
				'img-url': imgUrl('Social-skills/icon%202.webp'),
				text: 'Management of self - understanding emotions, expressing them etc.'
			},
			{
				'img-url': imgUrl('Social-skills/icon%203.webp'),
				text: 'Awareness of community and immediate surroundings'
			},
			{
				'img-url': imgUrl('Social-skills/icon%204.webp'),
				text: 'Awareness of the world - plants, animals, world cultures etc'
			},
			{
				'img-url': imgUrl('Social-skills/icon%205.webp'),
				text: 'Relationship awarenss  - Self, family, community'
			},
			{
				'img-url': imgUrl('Social-skills/icon%206.webp'),
				text: 'Social interaction - peer to peer and student-teacher'
			}
		]
	},
	'cognitive-usp-info': {
		header:
			'How does wonderLearn build their thinking and Executive function skills?',
		content: [
			{
				'img-url': imgUrl('Cognitive%20skills/icon%201.webp'),
				text: 'Open ended educational toys that let them play, think & learn'
			},
			{
				'img-url': imgUrl('Cognitive%20skills/icon%202.webp'),
				text: 'Puzzles for problem solving and discovery'
			},
			{
				'img-url': imgUrl('Cognitive%20skills/icon%203.webp'),
				text: 'Simple & engaging practice games on learning app'
			}
			// {
			// 	'img-url': imgUrl('Cognitive%20skills/icon%204.webp'),
			// 	text: 'Especially designed activities for cognitive skill developmen'
			// }
		]
	},
	'art-usp-info': {
		header: 'How does wonderLearn help',
		content: [
			{
				'img-url': imgUrl('Art%20skills/icon%201.webp'),
				text: 'Focus on specific art forms like sand art, fork painting, finger painting, etc.'
			},
			{
				'img-url': imgUrl('Art%20skills/icon%202.webp'),
				text: 'A tool to learn communication, early Numeracy, logical thinking etc.'
			},
			{
				'img-url': imgUrl('Art%20skills/icon%203.webp'),
				text: 'A medium to develop fine motor skills - playdough, rolling, kneading etc.'
			},
			{
				'img-url': imgUrl('Art%20skills/icon%204.webp'),
				text: 'Open ended art discussions to sparkle individual imagination and creativity'
			}
		]
	},
	'art-usp-info-2': {
		header: 'Transform your kid into a confident speaker',
		content: [
			{
				'img-url': imgUrl('Art%20skills/icon%201.webp'),
				text: 'Animal Puppets'
			},
			{
				'img-url': imgUrl('Art%20skills/icon%202.webp'),
				text: "Bubble Painting | The 3 R's Reduce, Reuse, Recycle"
			},
			{
				'img-url': imgUrl('Art%20skills/icon%203.webp'),
				text: 'Warm and Cool Colors | Concentric Circles Doodle ) Thread Art'
			}
		]
	},
	'home-rating-grid': {
		data: [
			imgUrl('Home/rating-01.webp'),
			imgUrl('Home/rating-02.webp'),
			imgUrl('Home/rating-03.webp')
		]
	}
};

export const learningToysPillars = [
	{
		'pillar-heading': 'Every Child Is An Artist',
		'pillar-heading-color': 'rgba(30, 131, 148, 1)',
		'pillar-paragraph':
			'Watch our little Picassos create their masterpiece, your child could be the next!',
		'pillar-points': [
			'Thoughtfully designed 400+ learning toys & activities',
			'Easy-to-use, self-explanatory and highly engaging',
			'Strengthen parent-child bond'
		],
		textDivBGColor: '#B0E1EA',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Attend a free trial class',
		'button-text-color': 'black',
		'button-url': 'https://calendly.com/d/cd4-m5r-pgj/wonderlearn-trial-class',
		'button-color': 'rgba(226, 216, 104, 1)',
		media: [
			'https://d37772fpgg0hig.cloudfront.net/assets/Videos/Y2Mate.is+-+Cognitive+Development+with+Colours++Preschool+learning+videos-pRR8nJwNreg-1080p-1643884663334.mp4'
		],
		'media-type': 'video'
	},
	{
		'pillar-heading': 'A Little Literature Never Hurts Anyone!',
		'pillar-heading-color': 'rgba(165, 30, 122, 1)',
		'pillar-paragraph':
			'Have fun with phonics and lay down your kid`s foundation for learning letters',
		'pillar-points': [
			'1:5 teacher to student ratio',
			'100% personal attention',
			'Enhance social and emotional skills with live interaction'
		],
		textDivBGColor: '#EED6E1',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Learn more about Phonics',
		'button-text-color': 'black',
		'button-url': '/communication-skills-for-kids',
		'button-color': 'rgba(226, 216, 104, 1)',
		media: [
			'https://d37772fpgg0hig.cloudfront.net/assets/Videos/Y2Mate.is+-+Glimpses+of+Phonics+Classes+%40wonderLearn-nzr6v5-lCF0-720p-1643884158885.mp4'
		],
		'media-type': 'video'
	},
	{
		'pillar-heading': "Playing While Learning? Yes, It's Possible!",
		'pillar-heading-color': 'rgba(154, 111, 61, 1)',
		'pillar-paragraph':
			'Try the wonderWay of learning for your little one with our smart toys and experienced teaching facility.',
		'pillar-points': [
			'100+ hours of online games, stories, puzzles, rhymes, etc.',
			'Flexible pre-recorded sessions of expert educators',
			'Make learning time a fun-family time'
		],
		textDivBGColor: '#EDEDC8',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Book a Free Trial Class',
		'button-text-color': 'white',
		'button-url': 'https://calendly.com/d/cd4-m5r-pgj/wonderlearn-trial-class',
		'button-color': 'rgb(25 160 185)',
		media: [
			'https://d37772fpgg0hig.cloudfront.net/assets/Videos/Y2Mate.is+-+Playing+While+Learning+Yes%2C+Its+Possible!-0livkpjlfD8-720p-1643883653329.mp4'
		],
		'media-type': 'video'
	}
];

export const interactiveClassesPillars = [
	{
		'pillar-paragraph':
			'Experience the wonder of live classes with our educators who leave no stone unturned to give your little one a delightful learning experience.',
		'pillar-points': [],
		textDivBGColor: '#EED6E1',
		media: ['https://www.youtube.com/watch?v=wX_vdQPg8xk'],
		'media-type': 'video',
		hasBtn: false
	},
	{
		'pillar-paragraph':
			"Hello there little ones! Let us take maths to a fun level with Unitle. Are you ready? Get your unitles and follow the instructions along with us to make a truck. Let's go!",
		'pillar-points': [],
		textDivBGColor: '#B0E1EA',
		media: ['https://www.youtube.com/watch?v=UkViKTlGNiY'],
		'media-type': 'video',
		hasBtn: false
	}
];

export const onlineKeyPillarsInfo = [
	{
		'pillar-heading': 'Every Child Is An Artist',
		'pillar-heading-color': 'rgba(30, 131, 148, 1)',
		'pillar-paragraph':
			'Watch our little Picassos create their masterpiece, your child could be the next!',
		'pillar-points': [
			'Thoughtfully designed 400+ learning toys & activities',
			'Easy-to-use, self-explanatory and highly engaging',
			'Strengthen parent-child bond'
		],
		textDivBGColor: 'rgba(128, 210, 224, 0.57)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Attend a free trial class',
		'button-text-color': 'black',
		'button-url': 'https://calendly.com/d/cd4-m5r-pgj/wonderlearn-trial-class',
		'button-color': 'rgba(226, 216, 104, 1)',
		media: [
			videoUrl(
				'Y2Mate.is+-+Cognitive+Development+with+Colours++Preschool+learning+videos-pRR8nJwNreg-1080p-1643884663334.mp4'
			)
		],
		'media-type': 'video'
	},
	{
		'pillar-heading': 'A Little Literature Never Hurts Anyone!',
		'pillar-heading-color': 'rgba(165, 30, 122, 1)',
		'pillar-paragraph':
			'Have fun with phonics and lay down your kid`s foundation for learning letters',
		'pillar-points': [
			'1:5 teacher to student ratio',
			'100% personal attention',
			'Enhance social and emotional skills with live interaction'
		],
		textDivBGColor: 'rgba(238, 214, 225, 1)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Learn more about Phonics',
		'button-text-color': 'black',
		'button-url': '/communication-skills-for-kids',
		'button-color': 'rgba(226, 216, 104, 1)',
		media: [
			videoUrl(
				'Y2Mate.is+-+Glimpses+of+Phonics+Classes+%40wonderLearn-nzr6v5-lCF0-720p-1643884158885.mp4'
			)
		],
		'media-type': 'video'
	},
	{
		'pillar-heading': "Playing While Learning? Yes, It's Possible!",
		'pillar-heading-color': 'rgba(154, 111, 61, 1)',
		'pillar-paragraph':
			'Try the wonderWay of learning for your little one with our smart toys and experienced teaching facility.',
		'pillar-points': [
			'100+ hours of online games, stories, puzzles, rhymes, etc.',
			'Flexible pre-recorded sessions of expert educators',
			'Make learning time a fun-family time'
		],
		textDivBGColor: 'rgba(239, 239, 202, 1)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Book a Free Trial Class',
		'button-text-color': 'white',
		'button-url': 'https://calendly.com/d/cd4-m5r-pgj/wonderlearn-trial-class',
		'button-color': 'rgb(25 160 185)',
		media: [
			videoUrl(
				'Y2Mate.is+-+Playing+While+Learning+Yes%2C+Its+Possible!-0livkpjlfD8-720p-1643883653329.mp4'
			)
		],
		'media-type': 'video'
	}
];

export const homeKeyPillarsInfo = [
	{
		'pillar-heading': 'Learning Toys',
		'pillar-heading-color': 'rgba(30, 131, 148, 1)',
		'pillar-paragraph':
			'Nurture your child’s creativity & imagination by making them touch, feel, play and learn with the toys that are absolute fun',
		'pillar-points': [
			'Thoughtfully designed 400+ learning toys & activities',
			'Easy-to-use, self-explanatory and highly engaging',
			'Strengthen parent-child bond'
		],
		textDivBGColor: 'rgba(128, 210, 224, 0.57)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Experience Our Toys',
		'button-text-color': 'black',
		'button-url': '/learning-educational-toys-for-preschool-kids',
		'button-color': 'rgba(226, 216, 104, 1)',
		media: [imgUrl('Home/learning-toys.webp')],
		'media-type': 'image'
	},
	{
		'pillar-heading': 'Live Interactive Classes',
		'pillar-heading-color': 'rgba(165, 30, 122, 1)',
		'pillar-paragraph':
			'Get your child prepared for a formal schooling with our personalized live sessions to enable peer-to-peer and teacher-student interaction',
		'pillar-points': [
			'1:5 teacher to student ratio',
			'100% personal attention',
			'Enhance social and emotional skills with live interaction'
		],
		textDivBGColor: 'rgba(238, 214, 225, 1)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Know more',
		'button-text-color': 'black',
		'button-url': '/online-classes-for-kids',
		'button-color': 'rgba(226, 216, 104, 1)',
		media: [imgUrl('Home/interactive_class_collage.webp')],
		'media-type': 'image'
	},
	{
		'pillar-heading': 'Learning App',
		'pillar-heading-color': 'rgba(154, 111, 61, 1)',
		'pillar-paragraph':
			'Reinforce and practice the imbibed concepts via toys and classes through varieties of digitally curated content',
		'pillar-points': [
			'100+ hours of online games, stories, puzzles, rhymes, etc.',
			'Flexible pre-recorded sessions of expert educators',
			'Make learning time a fun-family time'
		],
		textDivBGColor: 'rgba(239, 239, 202, 1)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Learn More',
		'button-text-color': 'white',
		'button-url': '/online-games-for-kids',
		'button-color': 'rgb(25 160 185)',
		media: [imgUrl('learning-app-show-img.webp')],
		'media-type': 'image'
	}
];

export const expKeyPillarsInfo = [
	{
		'pillar-heading': 'The classic game of snakes and ladders!',
		'pillar-heading-color': 'rgba(165, 30, 122, 1)',
		'pillar-paragraph':
			'Teach numbers to your little one via this game! The very interesting unitile approach and much more, to teach numeracy skills.',
		'pillar-points': [],
		textDivBGColor: 'rgb(238 214 225)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Explore our Learning Toys',
		'button-text-color': 'white',
		'button-url':
			'https://www.thewonderlearn.com/learning-educational-toys-for-preschool-kids',
		'button-color': 'rgb(25 160 185)',
		media: [
			videoUrl(
				'Y2Mate.is+-+Preschool+Learning++Numeracy++Learn+Numbers++Unitile+Approach++%23ToysMeetTech-jbIl_6N3yPU-1080p-1643884712629.mp4'
			)
		],
		'media-type': 'video'
	},
	{
		'pillar-heading': 'A complete balance of toys & learning app!',
		'pillar-heading-color': 'rgba(30, 131, 148, 1)',
		'pillar-paragraph':
			"Enhance your child's audio-visual intelligence and make learning easy and fun",
		'pillar-points': [],
		textDivBGColor: 'rgba(176 225 234)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(238 214 225)',
		'button-text': 'Explore our Online games',
		'button-color': 'rgb(226 216 104)',
		'button-text-color': 'black',
		'button-url': 'https://www.thewonderlearn.com/online-games-for-kids',
		media: [
			videoUrl(
				'Y2Mate.is+-+Preschool+Learning++Phonics%2C+Alliteration+-+Learn+with+Sounds%2C+Toys%2C+Rhyming+words++%23ToysMeetTech-yGVv91lkG3Y-1080p-1643884390145.mp4'
			)
		],
		'media-type': 'video'
	}
];

export const mpKeyPillarsInfo = [
	{
		'pillar-heading': '',
		'pillar-heading-color': '',
		'pillar-paragraph':
			'Teach Phonics through child centered approach using songs, actions, fun toys and more. Enhance your child`s audio visual intelligence and make learning easy and fun with our phonics wheel.',
		'pillar-points': [],
		textDivBGColor: 'rgb(238 214 225)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(175 224 233)',
		'button-text': 'Book a free demo',
		'button-text-color': 'white',
		'button-url': 'https://calendly.com/wl-trial-class/15min',
		'button-color': 'rgb(25 160 185)',
		media: [
			imgUrl('phonics%20wheels-01-01.webp')
			// imgUrl('sound%20clip%20cards-01-01.webp'),
			// imgUrl('magnet%20tiles-01-01.webp')
		],
		'media-type': 'image'
	},
	{
		'pillar-heading': '',
		'pillar-heading-color': '',
		'pillar-paragraph':
			"Alliterations help kids learn about sound and also increase their vocabulary. Enhance your child's audio-visual intelligence and make learning easy and fun with our very own phonics wheel.",
		'pillar-points': [],
		textDivBGColor: 'rgba(176 225 234)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(238 214 225)',
		'button-text': 'Phonics Program',
		'button-color': 'rgb(226 216 104)',
		'button-text-color': 'black',
		'button-url':
			'https://wonderhood.in/pages/wonderlearn-young-reader-phonics-program',
		media: [
			'https://d37772fpgg0hig.cloudfront.net/assets/Videos/Y2Mate.is+-+Preschool+Learning++Phonics%2C+Alliteration+-+Learn+with+Sounds%2C+Toys%2C+Rhyming+words++%23ToysMeetTech-yGVv91lkG3Y-1080p-1643884390145.mp4'
		],
		'media-type': 'video'
	}
];

export const socialKeyPillarsInfo = [
	{
		'pillar-heading': '',
		'pillar-heading-color': '',
		'pillar-paragraph':
			"Can your little one name all the features on your face and then on your body? Let's do it together! We also have an activity to revise the human body. Get on a journey with us where we match the correct body parts and make our own humans!",
		'pillar-points': [],
		textDivBGColor: 'rgba(176 225 234)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(238 214 225)',
		'button-text': 'Book a free demo',
		'button-color': 'rgb(226 216 104)',
		'button-text-color': 'black',
		'button-url': 'https://calendly.com/wl-trial-class/15min',
		media: ['https://youtu.be/LThKJrEzRcY'],
		'media-type': 'video'
	}
];

export const cognitiveKeyPillarsInfo = [
	{
		'pillar-heading': '',
		'pillar-heading-color': '',
		'pillar-paragraph':
			"Honing one's cognitive skills is very crucial during one's childhood. Get a sneak peek at one of our colour block puzzles which help in enhancing your child's cognitive skills. They help in colour and shape identification, pattern recognition, and sequencing.",
		'pillar-points': [],
		textDivBGColor: 'rgba(176 225 234)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(238 214 225)',
		'button-text': 'Book a free demo',
		'button-color': 'rgb(226 216 104)',
		'button-text-color': 'black',
		'button-url': 'https://calendly.com/wl-trial-class/15min',
		media: [
			'https://d37772fpgg0hig.cloudfront.net/assets/Videos/Y2Mate.is+-+Cognitive+Development+with+Colours++Preschool+learning+videos-pRR8nJwNreg-1080p-1643884663334.mp4'
		],
		'media-type': 'video'
	},
	{
		'pillar-heading': '',
		'pillar-heading-color': '',
		'pillar-paragraph':
			"With wonderLearn, you can teach your child maths in a creative manner using the unitle approach. Your preschooler will find this concept easy and fun to play with. This will not only increase your child's numeracy skills but also enhance motor skills.",
		'pillar-points': [],
		textDivBGColor: 'rgba(176 225 234)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(238 214 225)',
		'button-text': 'Book a free demo',
		'button-color': 'rgb(226 216 104)',
		'button-text-color': 'black',
		'button-url': 'https://calendly.com/wl-trial-class/15min',
		media: [
			'https://d37772fpgg0hig.cloudfront.net/assets/Videos/Y2Mate.is+-+Preschool+Learning++Numeracy++Learn+Numbers++Unitile+Approach++%23ToysMeetTech-jbIl_6N3yPU-1080p-1643884712629.mp4'
		],
		'media-type': 'video'
	}
];

export const artKeyPillarsInfo = [
	{
		'pillar-heading': '',
		'pillar-heading-color': '',
		'pillar-paragraph':
			'This easy-to-learn video is a fun and engaging way to teach your children colors and shapes.',
		'pillar-points': [],
		textDivBGColor: 'rgba(176 225 234)',
		btnColor: 'rgb(231 222 120)',
		headingColor: 'rgb(30 131 148)',
		extraColor: 'rgb(238 214 225)',
		'button-text': 'Book a free demo',
		'button-color': 'rgb(226 216 104)',
		'button-text-color': 'black',
		'button-url': 'https://calendly.com/wl-trial-class/15min',
		media: ['https://youtu.be/ZYwyWpUBJ4g'],
		'media-type': 'video'
	}
];

export const mpCurriculumInfo = {
	heading: 'What will my child learn ?',
	curriculumCategory: [],
	subjectDetails: [
		{
			subjectHeading: '2-3 Year (Toddler)',
			quote: '“I look at the world around me and try to understand what I see”',
			topics: [
				'Environment sounds, rhymes',
				'Exploring sounds',
				'Alliteration, Word box',
				'Jungle animal sounds, Bird sounds'
			],
			bgColor: ' rgb(176 210 216)',
			headingColor: 'rgb(30 131 148)'
		},
		{
			subjectHeading: '3-4 years (LKG)',
			quote:
				'“I hear many things- sounds and voices. I try and make some of my own”',
			topics: [
				'Phonics SATPIN, CKEH, RMD Sound Matching',
				'Phonics GOULFB, Letter Sounds | Sound Matching',
				'Ultimate Vowel Sounds Tricky Sounds',
				'Rhyming Words | Rhymes | Sound Groups'
			],
			bgColor: 'rgb(234 234 190)',
			headingColor: 'rgb(163 142 117)'
		},
		{
			subjectHeading: '4-6 years (UKG)',
			quote:
				"“I see things around me- .Some look like me, some don't. I'm still learning”",
			topics: [
				'Sound Group Practice Letter Formation',
				'Tricky Words | Beginning Sound Words | Word Formation',
				'More Tricky Words | Learning Beginning Sounds',
				'Pattern Making | Tracing Words Via Songs|| Digraph Practice | Repetitive Pattern'
			],
			bgColor: 'rgb(235 208 225)',
			headingColor: 'rgb(165 30 122)'
		}
	]
};

export const socialCurriculumInfo = {
	heading: 'What will my child learn ?',
	curriculumCategory: [],
	subjectDetails: [
		{
			subjectHeading: '2-3 Year (Toddler)',
			quote: '',
			topics: [
				'Face & basic body part awareness',
				'Fruits, Vegetables, Transportation',
				'Seasons',
				'Sun, Moon, Starts, Planets'
			],
			bgColor: ' rgb(176 210 216)',
			headingColor: 'rgb(30 131 148)'
		},
		{
			subjectHeading: '3-4 years (LKG)',
			quote: '',
			topics: [
				'Me, My Family and Community',
				'Seasons World Festivals | Domestic Animals',
				'Farm Animals Sea Creatures Pets Birds | Bugs | Planet',
				"The 3 R's Story Telling | Rhymes"
			],
			bgColor: 'rgb(234 234 190)',
			headingColor: 'rgb(163 142 117)'
		},
		{
			subjectHeading: '4-6 years (UKG)',
			quote: '',
			topics: [
				'My School, My Home, My City',
				'Exploring the World: People, Food, Festivals, Languages',
				'Exploring the World: Landforms, Animals, Plants',
				'Exploring the World: Planets, Solar System'
			],
			bgColor: 'rgb(235 208 225)',
			headingColor: 'rgb(165 30 122)'
		}
	]
};

export const cognitiveCurriculumInfo = {
	heading: 'What will my child learn ?',
	curriculumCategory: [],
	subjectDetails: [
		{
			subjectHeading: '2-3 Year (Toddler)',
			quote: '',
			topics: [
				'Colors, shapes, sorting, lacing, matching',
				'Color mixing, float & sink',
				'Healthy Eating Habits, Basic Nutrition',
				'Understanding emotions, Quantity'
			],
			bgColor: ' rgb(176 210 216)',
			headingColor: 'rgb(30 131 148)'
		},
		{
			subjectHeading: '3-4 years (LKG)',
			quote: '',
			topics: [
				'Numbers 1-301 Sequencing Match and Order',
				'Spatial Positions Measurement | Quantity',
				'Basics of Addition and Subtraction Measurement',
				'Backward Counting | Time Patterns'
			],
			bgColor: 'rgb(234 234 190)',
			headingColor: 'rgb(163 142 117)'
		},
		{
			subjectHeading: '4-6 years (UKG)',
			quote: '',
			topics: [
				'Odd and Even | Read and Write 0-10 Number Sequencing',
				'Compare and Order Measurement | Shapes',
				'Addition | Subtraction Simple Word Problems | Coins',
				'Calculation | Time | 2D and 3D Shapes Models'
			],
			bgColor: 'rgb(235 208 225)',
			headingColor: 'rgb(165 30 122)'
		}
	]
};

export const artCurriculumInfo = {
	heading: 'What will my child learn ?',
	curriculumCategory: [],
	subjectDetails: [
		{
			subjectHeading: '2-3 Year (Toddler)',
			quote: '',
			topics: ['Fun with play dough', 'Fun play', 'Body Percussion'],
			bgColor: ' rgb(176 210 216)',
			headingColor: 'rgb(30 131 148)'
		},
		{
			subjectHeading: '3-4 years (LKG)',
			quote: '',
			topics: [
				'Sponge Painting | Paper Shapes | Roller & Scraper Painting',
				'Stamp Art | Warli Art',
				'Folk Art | Free Drawing | Clay Modelling'
			],
			bgColor: 'rgb(234 234 190)',
			headingColor: 'rgb(163 142 117)'
		},
		{
			subjectHeading: '4-6 years (UKG)',
			quote: '',
			topics: [
				'Warm and Cool Colors | Concentric Circles Doodle ) Thread Art',
				'Charcoal Crayons | Pastels| Stamp Art Collage Making',
				'Quilling | South American Art | Australian Art'
			],
			bgColor: 'rgb(235 208 225)',
			headingColor: 'rgb(165 30 122)'
		}
	]
};

export const homeTestimonials = [
	{
		background: imgUrl('home-testomonial-1.webp'),
		message:
			'Your product is different and more comprehensive than others in the market',
		writter: 'Jason, Father of Jose'
	},
	{
		background: imgUrl('home-testomonial-5.webp'),
		message:
			'There are soo many elements in the overall program, i find it comprehensive and complete.',
		writter: 'Saumya, Mother of Ayah'
	},
	{
		background: imgUrl('home-testomonial-3.webp'),
		message:
			"Each activity is thoughtfully sketched and designed.I'm happy with the course content.",
		writter: 'Sakshi, Mother of Akshita'
	},
	{
		background: imgUrl('home-testomonial-2.webp'),
		message:
			'There are soo many elements in the overall program, i find it comprehensive and complete.',
		writter: 'Samay, Father of Rhea'
	},
	{
		background: imgUrl('home-testomonial-4.webp'),
		message:
			'There are soo many elements in the overall program, i find it comprehensive and complete.',
		writter: 'Asha, Mother of Ayan'
	}
];

export const interactiveCarousel = [
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-7.jpg?v=1625642181',
		paragraph:
			'Trained by Knowledge Universe of Singapore and certified in Jolly Phonics/ Literacy, Dinpiliu has 5+ years at international chain Bright Horizons and loves to be around children. She is a calm, patient and resourceful person. She believes that teaching is a very noble profession where one can nurture and mould children into better individuals and hence so much passion towards it. Dinpiliu builds a trusting, positive relationship and always have positive interaction with children.',
		name: 'Dinpiliu Charenamei',
		position: 'Educator/Academic Counsellor',
		linkedinUrl: 'https://www.linkedin.com/in/dinpiliu-charenamei-2305791b8/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-8.jpg?v=1625642324',
		paragraph:
			'Trained by Knowledge Universe of Singapore, Pinky has 7+ years of kindergarten experience at international chain Bright Horizons. Working in the field of Early Childhood Education has always been her passion. She loves interacting with children, observing them and understanding each one of them. She believes each child is precious and unique and has the right to be heard, seen, loved and respected. She loves music and books. ',
		name: 'Pinky Pradhan',
		position: 'Senior Teacher/ Counsellor',
		linkedinUrl: 'https://www.linkedin.com/in/pinky-pradhan-8b07191b9/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/Tamanna_Pic_ffca1565-ac10-4161-bc0f-61596e35f1ce.jpg?v=1628177166',
		paragraph:
			'Positively Motivated,energetic team member who has a strong desire to grow in the field of early childhood education. Trained in ECCE with 3 years experience as a pre-school teacher and administration in Toddlers International School.Completed B.ed and passed CTET .Commited to raising Standards and maximising pupil progress.Have long track record of delivering high quality care and learning opportunities to children between 2-5 years. Apart from the subject matter, Tamanna provides parents with advice and mentoring, makes easy to work in hand for the progress of the child. Her quote for teaching is "It\'s not only teaching,but it takes a lot of effort and time to train yourself to be more than just a teacher." She believes teaching this age group is like moulding a raw clay and planting strong roots for the growth and development of a child.',
		name: 'Tamanna Basoya',
		position: 'Educator',
		linkedinUrl: 'https://www.linkedin.com/in/tamanna-basoya-a04627213/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/Deigratia_Photo.jpg?v=1628177345',
		paragraph:
			'A passionate and enthusiastic Pre- school teacher with 3 years of experience at Brats n Cuties, Miss Deigratia has completed her NPTT and ECCE from International Women Polytechnic, New Delhi. She is devoted to raising standards and maximizing each child\'s progress. She believes in building a strong bond with the children and parent partners, at the same time she is committed in creating a fun and healthy learning environment. Her motto in life is "Do everything with so much love in your heart that you would never want to do it in any other way"',
		name: 'Deigratia Dina Bareh',
		position: 'Educator',
		linkedinUrl: 'https://www.linkedin.com/in/deigratia-bareh-444b30148/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-2.jpg?v=1625636323',
		paragraph:
			'Teaching is her passion, profession and love for kids is endless. With a teaching experience of over 7 years, Srilaxmi has worked for kids friendly companies like KUMON,CUEMATH,PLABO and is exposed to different methodologies. For her,""Every child is different and every child has their own talent"". She always tries and understands the child first and then help them grow accordingly in small increasing steps.This way the bond stays for ever and kids learn a lot in this fun process.',
		name: 'Srilaxmi Patnala',
		position: 'Educator/Academic Counsellor',
		linkedinUrl: 'https://www.linkedin.com/in/srilaxmi-patnala-1a357b189/'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-1.jpg?v=1625636131',
		paragraph:
			'An artist, story-teller and "in her room" musician, Aditi loves spending time teaching little children. She pursued her teacher training under the steiner/waldorf method of education, and has also worked for 2 years with special needs children for 2 years. She currently works with us as an educator and curriculum planner on the education front. Her interests include art, travel, music, animals, writing and reading.',
		name: 'Aditi Sriprasad',
		position: 'Manager - Content, Curriculum, Creatives',
		linkedinUrl: 'https://www.linkedin.com/in/aditi-sriprasad-17226891/'
	},
	{
		image:
			'http://d37772fpgg0hig.cloudfront.net/assets/images/About+Us/IMG_20210817_155343.webp',
		paragraph:
			"Holding 5+ years of experience as an educator with Eastwood School, SSB International School and Bright Horizons, Shilpa ensures every child's learning styles and abilities are addressed. She has completed Diploma in NTT and ECCE from NZTC and  sheis very passionate about early childhood education. She believes every child needs a solid education and core foundation to be successful in life.",
		name: 'Shilpa .M',
		position: 'Educator',
		linkedinUrl: 'http://www.linkedin.com/in/shilpa-mukunda-805356125'
	}
];
export const magicGrid = [
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-7.jpg?v=1625642181',
		paragraph:
			'Trained by Knowledge Universe of Singapore and certified in Jolly Phonics/ Literacy, Dinpiliu has 5+ years at international chain Bright Horizons and loves to be around children. She is a calm, patient and resourceful person. She believes that teaching is a very noble profession where one can nurture and mould children into better individuals and hence so much passion towards it. Dinpiliu builds a trusting, positive relationship and always have positive interaction with children.',
		name: 'Dinpiliu Charenamei',
		position: 'Educator/Academic Counsellor',
		linkedinUrl: 'https://www.linkedin.com/in/dinpiliu-charenamei-2305791b8/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-8.jpg?v=1625642324',
		paragraph:
			'Trained by Knowledge Universe of Singapore, Pinky has 7+ years of kindergarten experience at international chain Bright Horizons. Working in the field of Early Childhood Education has always been her passion. She loves interacting with children, observing them and understanding each one of them. She believes each child is precious and unique and has the right to be heard, seen, loved and respected. She loves music and books. ',
		name: 'Pinky Pradhan',
		position: 'Senior Teacher/ Counsellor',
		linkedinUrl: 'https://www.linkedin.com/in/pinky-pradhan-8b07191b9/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/Tamanna_Pic_ffca1565-ac10-4161-bc0f-61596e35f1ce.jpg?v=1628177166',
		paragraph:
			'Positively Motivated,energetic team member who has a strong desire to grow in the field of early childhood education. Trained in ECCE with 3 years experience as a pre-school teacher and administration in Toddlers International School.Completed B.ed and passed CTET .Commited to raising Standards and maximising pupil progress.Have long track record of delivering high quality care and learning opportunities to children between 2-5 years. Apart from the subject matter, Tamanna provides parents with advice and mentoring, makes easy to work in hand for the progress of the child. Her quote for teaching is "It\'s not only teaching,but it takes a lot of effort and time to train yourself to be more than just a teacher." She believes teaching this age group is like moulding a raw clay and planting strong roots for the growth and development of a child.',
		name: 'Tamanna Basoya',
		position: 'Educator',
		linkedinUrl: 'https://www.linkedin.com/in/tamanna-basoya-a04627213/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/Deigratia_Photo.jpg?v=1628177345',
		paragraph:
			'A passionate and enthusiastic Pre- school teacher with 3 years of experience at Brats n Cuties, Miss Deigratia has completed her NPTT and ECCE from International Women Polytechnic, New Delhi. She is devoted to raising standards and maximizing each child\'s progress. She believes in building a strong bond with the children and parent partners, at the same time she is committed in creating a fun and healthy learning environment. Her motto in life is "Do everything with so much love in your heart that you would never want to do it in any other way"',
		name: 'Deigratia Dina Bareh',
		position: 'Educator',
		linkedinUrl: 'https://www.linkedin.com/in/deigratia-bareh-444b30148/'
	},
	{
		image:
			'https://cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-2.jpg?v=1625636323',
		paragraph:
			'Teaching is her passion, profession and love for kids is endless. With a teaching experience of over 7 years, Srilaxmi has worked for kids friendly companies like KUMON,CUEMATH,PLABO and is exposed to different methodologies. For her,""Every child is different and every child has their own talent"". She always tries and understands the child first and then help them grow accordingly in small increasing steps.This way the bond stays for ever and kids learn a lot in this fun process.',
		name: 'Srilaxmi Patnala',
		position: 'Educator/Academic Counsellor',
		linkedinUrl: 'https://www.linkedin.com/in/srilaxmi-patnala-1a357b189/'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-1.jpg?v=1625636131',
		paragraph:
			'An artist, story-teller and "in her room" musician, Aditi loves spending time teaching little children. She pursued her teacher training under the steiner/waldorf method of education, and has also worked for 2 years with special needs children for 2 years. She currently works with us as an educator and curriculum planner on the education front. Her interests include art, travel, music, animals, writing and reading.',
		name: 'Aditi Sriprasad',
		position: 'Manager - Content, Curriculum, Creatives',
		linkedinUrl: 'https://www.linkedin.com/in/aditi-sriprasad-17226891/'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-3.jpg?v=1625636472',
		paragraph:
			'A smooth multi tasker, people person with caring attitude. Continuously handling dynamic role in educating and guiding the parents for an Early intervention of pre schooling for toddlers or with the special need children. Always a got getter and prefers to finish the given task the same day. A coffee lover and loves to go for long walks and play Badminton.',
		name: 'Neha Khetarpal',
		position: 'Customer Relation Specialist',
		linkedinUrl: 'https://www.linkedin.com/in/neha-khetarpal-90577b70'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-4.jpg?v=1625636719',
		paragraph:
			'A strategist at heart with creative growth mindset and execution excellence. Ankur is passionate about brand and product marketing. Already building out high-caliber marketing programs focused on direct revenue contribution as well as delivering a superior brand experience. Beyond work, you will find him sketching, painting, reading, sports (newly found interest) and with his loved ones.',
		name: 'Ankur Tandon',
		position: 'Manager- Marketing & Branding',
		linkedinUrl: 'https://www.linkedin.com/in/ankur-tandon-636332a7/'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-5.png?v=1625636968',
		paragraph:
			'With a strong sales experience in ed-tech firms like Byjus, Whitehatjr and Oyo in the hospitality sector, Shivanand comes with a customer-centric approach. He has received several awards for his contribution in sales and growth of the organisations. He is really passionate about meeting and interacting with new people which makes him a great problem solver. ',
		name: 'Tammewar Shivanand',
		position: 'Associate Sales Manager',
		linkedinUrl: 'https://www.linkedin.com/in/tammewarshivanand/'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/wh-au-mm-6.jpg?v=1625637327',
		paragraph:
			'Graphic designer, artist, a learner, part-time mural artist who loves art supplies more than anything else with having strong passion and interest in art and design. Her passion for art always motivates her to keep it alive. Her only goal is to always follow passion with dedication and honesty. Additionally, Shivani loves to capture nature and is obsessed with flowers.',
		name: 'Shivani Sharma',
		position: 'Graphic Designer/illustrator',
		linkedinUrl: 'https://www.linkedin.com/in/shivani-sharma-18716115a/'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/Sunaina_4c7d594c-409a-49e6-8fdd-480e4c3b1da5.jpg?v=1628177625',
		paragraph:
			'Innovative, versatile and creative marketing assistant with the skills, passion and professionalism needed to support Education Sector to promote products and services and conduct detailed market research, Through being able to understand the wider picture, earned a reputation as someone offers a pro-active approach and is recognised as being results driven and an effective team player . Can lead a team as an Experienced Marketing Executive with a demonstrated history of working in the education management industry.',
		name: 'Sunaina Thapar',
		position: 'Customer Success Manager/Operations',
		linkedinUrl: 'https://www.linkedin.com/in/sunaina-thapar-b67070190'
	},
	{
		image:
			'//cdn.shopify.com/s/files/1/0007/0028/5046/files/Shubham_Photo.jpg?v=1628177820',
		paragraph:
			'A strong leader, experienced and self-motivated sales manager with more than 3 years of experience from corporates like Amazon and Byjus, Shubham brings a proven track record of working collaboratively with sales teams to achieve goals, increase revenue gains and deliver the best customer experience. For him, sales has always been about solving a problem or fulfilling the need with building a relationship and loyalty that stays forever.',
		name: 'Shubham Pratap Singh',
		position: 'Associate Sales Manager',
		linkedinUrl: 'https://www.linkedin.com/in/shubham-singh-89037620a'
	}
];

export const learningToysClassesCard = {
	heading: 'Avail teachers support and highly engaging digital content',
	headingColor: '#1E8394',
	bgColor: 'white',
	cardData: [
		{
			bgColor: '#f2f2f2',
			heading: 'Live Classes',
			btnText: 'Interactive Classes',
			btnColor: '#4BAFC1',
			img: imgUrl('Home/interactive_class_collage.webp'),
			link: '/online-classes-for-kids'
		},
		{
			bgColor: '#f2f2f2',
			heading: 'Online Games',
			btnText: 'See Games',
			btnColor: '#4BAFC1',
			img: imgUrl('learning-app-show-img.webp'),
			link: '/online-games-for-kids'
		}
	]
};

export const interactiveClassesCard = {
	heading: 'Avail teachers support and highly engaging digital content',
	headingColor: '#1E8394',
	bgColor: '#F1F7F9',
	cardData: [
		{
			bgColor: '#f2f2f2',
			heading: 'Learning Toys',
			btnText: 'Our Toys',
			btnColor: '#4BAFC1',
			img: imgUrl('Home/learning-toys.webp'),
			link: '/learning-educational-toys-for-preschool-kids'
		},
		{
			bgColor: '#f2f2f2',
			heading: 'Online Games',
			btnText: 'See Games',
			btnColor: '#4BAFC1',
			img: imgUrl('learning-app-show-img.webp'),
			link: '/online-games-for-kids'
		}
	]
};

export const gamesClassesCard = {
	heading: 'Avail teachers support and highly engaging digital content',
	headingColor: '#1E8394',
	bgColor: '#F1F7F9',
	cardData: [
		{
			bgColor: '#f2f2f2',
			heading: 'Learning Toys',
			btnText: 'Our Toys',
			btnColor: '#4BAFC1',
			img: imgUrl('Home/learning-toys.webp'),
			link: '/learning-educational-toys-for-preschool-kids'
		},
		{
			bgColor: '#f2f2f2',
			heading: 'Live Classes',
			btnText: 'Interactive Classes',
			btnColor: '#4BAFC1',
			img: imgUrl('Home/interactive_class_collage.webp'),
			link: '/online-classes-for-kids'
		}
	]
};

export const interactiveClassesBanner = {
	'banner-title-parts': ['wonder', 'Learn'],
	'banner-subHeading':
		'Online Classes for Kids: Visualize the Everlasting Wonder',
	'banner-points': [
		'Experience the best-in-class teaching with the most innovative learning toys & tools'
	],
	'banner-cta': {
		text: 'Book a free demo',
		url: '/plans-and-pricing'
	},
	'banner-slider-images': [imgUrl('Home/home-banner-1.webp')]
};

export const interactiveClassesCards = {
	heading: 'wonderLearn- The Most Engaging Online Classes For Preschoolers!',
	headingColor: '#1E8394',
	cardsData: [
		{
			img: imgUrl('interactive-1.webp'),
			alt: '',
			heading: 'Engaging, really?',
			text: '100% guaranteed! Your child will remain seated for the entire class'
		},
		{
			img: imgUrl('interactive-2.webp'),
			alt: '',
			heading: 'Timings clash?',
			text: 'Learn from anywhere and anytime, at your own desired pace'
		},
		{
			img: imgUrl('interactive-3.webp'),
			alt: '',
			heading: 'Want to customize?',
			text: 'Plan as per your kid’s needs, age, duration of learning, focus on a particular skill, etc.'
		},
		{
			img: imgUrl('interactive-4.webp'),
			alt: '',
			heading: 'Want full attention?',
			text: '1:5 teacher-to-student ratio to ensure individual attention'
		},
		{
			img: imgUrl('interactive-5.webp'),
			alt: '',
			heading: 'Missed a class?',
			text: 'No chance! Watch the recording later in our learning app'
		},
		{
			img: imgUrl('icon+6.webp'),
			alt: '',
			heading: 'Not enough?',
			text: '45 mins classes, 5 times a week. What more?'
		}
	]
};
