import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import MagicSliderDots from 'react-magic-slider-dots';
import CCardContainer from './CCard.styled';

const CCard = ({ RenderCard, Data }) => {
	const settings = {
		dots: true,
		arrows: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		appendDots: dots => {
			return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
		}
	};

	return (
		<CCardContainer>
			<div className='media-wrapper'>
				<Slider {...settings}>
					{Data.map(e => (
						<div className='slide'>
							<RenderCard data={e} />
						</div>
					))}
				</Slider>
			</div>
		</CCardContainer>
	);
};

export default CCard;
