export const moneyText = {
	USD: '$',
	INR: '₹',
	AED: '	د.إ'
};

export const INITIAL_CART_STATE = {
	subTotal: '$0',
	currency: 'USD',
	shopifyUrl: '',
	items: []
};

export const cartItem = {
	ADD_CART_ITEM: 'ADD_CART_ITEM',
	REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
	CLEAR_CART_ITEM: 'CLEAR_CART_ITEM',
	CLEAR_CART: 'CLEAR_CART'
};

export const updateCart = {
	UPDATE_CURRENCY: 'UPDATE_CURRENCY',
	UPDATE_SUBTOTAL: 'UPDATE_SUBTOTAL',
	UPDATE_SHOPIFY_URL: 'UPDATE_SHOPIFY_URL'
};
