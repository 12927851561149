import './PricingPageBanner.sass';

function PricingPageBanner() {
	return (
		<div className='pricingPageContainer'>
			<h1> Our Learning Programs</h1>
			<p>The Most Revolutionary Early Learning Partner. No Kid-ding!</p>
		</div>
	);
}

export default PricingPageBanner;
