import './App.sass';
import { useEffect, lazy, Suspense } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useLocation
} from 'react-router-dom';
import { openDemoModal, setWindowSize } from './ReduxStore/App/App.Actions';
import { useDispatch, useSelector } from 'react-redux';
import { MixpanelProvider } from 'react-mixpanel-browser';
import { FetchPlansPricingStart } from './ReduxStore/Shop/Shop.Actions';
import FooterSticky from './pages/Component/FooterSticky/FooterSticky';

import TestimonialNew from './pages/Component/TestimonialNew/TestimonialNew';
import { homeTestimonials as testimonials } from './values/Values';
import Explorer from './pages/Explorer/Explorer';
import { newSiteLink } from './assets/utils';

const Nav = lazy(() => import('./pages/Component/Nav/Nav'));
const Footer = lazy(() => import('./pages/Component/Footer/Footer'));
const BookingForm = lazy(() => import('./pages/Component/DemoModal/DemoModal'));
const GameModal = lazy(() => import('./pages/Component/GameModal/GameModal'));

const ArtSkill = lazy(() => import('./pages/ArtSkillPage/ArtSkillPage'));
const Cart = lazy(() => import('./pages/Cart/Cart'));
const CognitiveSkill = lazy(() =>
	import('./pages/CognitiveSkillPage/CognitiveSkillPage')
);
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const InteractiveClasses = lazy(() =>
	import('./pages/InteractiveClasses/InteractiveClasses')
);
const LearningToys = lazy(() => import('./pages/LearningToys/LearningToys'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));
const OnlineGames = lazy(() => import('./pages/OnlineGames/OnlineGames'));
const Phonic = lazy(() => import('./pages/Phonic/Phonic'));
const SocialSkill = lazy(() =>
	import('./pages/SocialSkillPage/SocialSkillPage')
);
const WinterLand = lazy(() => import('./pages/WinterLand/WinterLand'));
const Test = lazy(() => import('./pages/Test/Test'));


const App = () => {
	// const loadDolphin = () => {
	// 	const script = document.createElement('script');
	// 	script.async = true;
	// 	script.src = 'https://static.dolphinchat.ai/plugin/wa/js/wabot-1.0.js';
	// 	//For head
	// 	document.head.appendChild(script);

	// 	// For body
	// 	document.body.appendChild(script);
	// };

	const dispatch = useDispatch();
	const isFetched = useSelector(state => state.shopReducer.isFetched);

	useEffect(() => {
		if (isFetched) return;
		dispatch(FetchPlansPricingStart());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		loadDolphin();
	// 	}, 5000);
	// }, []);

	window.onresize = dispatch.bind(
		null,
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		})
	);

	const { hash } = useLocation();

	useEffect(() => {
		if (hash.includes('#free-demo')) dispatch(openDemoModal());
	}, [dispatch, hash]);

	return (
		<MixpanelProvider>
			<Suspense fallback={null}>
				<Router>
					<Nav />
					<BookingForm />
					<GameModal />
					<div className='mt-19'>
						<Switch>
							<Route path='/testimonial' exact>
								<TestimonialNew
									heading='What Our Happy Customers Say?'
									testimonials={testimonials}
								/>
							</Route>
							<Route
								exact
								path='/'
								render={() => {
									window.location.href = newSiteLink('');
									return <Loader />;
								}}
							/>
							<Route path='/wonderlearn-explorer' component={Explorer} />
							<Route path='/social-skills-for-kids' component={SocialSkill} />
							<Route
								path='/cognitive-skills-for-kids'
								component={CognitiveSkill}
							/>
							<Route path='/art-skills-for-kids' component={ArtSkill} />

							<Route path='/learning-toys' component={LearningToys} />

							<Route
								path='/interactive-classes'
								component={InteractiveClasses}
							/>
							<Route path='/online-games-for-kids' component={OnlineGames} />
							<Route path='/contact-us' component={ContactUs} />
							<Route path='/phonic' component={Phonic} />
							<Route path='/cart' component={Cart} />
							<Route path='/winter-land' component={WinterLand} />
							<Route path='/winter-land-event' component={WinterLand} />
							<Route path='/test-p' component={Test} />
							<Route
								render={props => {
									for (const [pathKey, pathValue] of Object.entries(
										redirectUrls
									))
										if (pathKey.includes(props.location.pathname)) {
											window.location.href = pathValue;
											return <Loader />;
										}

									return <NotFound />;
								}}
							/>
						</Switch>
						{/* <div className='dolphinchat-app-3e51d9aa6dcd4117a2f8db8c334a636e App'> */}
						<Footer />
						<FooterSticky />
					</div>
				</Router>
			</Suspense>
		</MixpanelProvider>
	);
};

const Loader = () => (
	<div className='relative w-screen h-[50vh] grid place-items-center left-0 lg:-left-1/4 cursor-wait'>
		<div className='spin animate-spin bg-transparent border-x-4 border-primary_1 w-24 h-24 rounded-full' />
	</div>
);

const redirectUrls = {
	'https://www.wonderhood.in/pages/learning-educational-toys-for-preschool-kids/':
		'https://www.thewonderlearn.com/learning-educational-toys-for-preschool-kids',
	'https://wonderhood.in/pages/online-preschool-classes-for-kids/':
		'https://www.thewonderlearn.com/online-preschool-classes-for-kids',
	'https://www.wonderhood.in/pages/online-classes-for-kids/':
		'https://www.thewonderlearn.com/online-classes-for-kids',
	'https://www.wonderhood.in/pages/blogs/':
		'https://www.thewonderlearn.com/blogs',
	'https://www.wonderhood.in/pages/plans-and-pricing/':
		'https://www.thewonderlearn.com/plans-and-pricing',
	'https://www.wonderhood.in/pages/faq/': 'https://www.thewonderlearn.com/faq',
	'https://www.wonderhood.in/pages/about-us/':
		'https://www.thewonderlearn.com/about-us',
	'https://www.wonderhood.in/pages/terms-of-service/':
		'https://www.thewonderlearn.com/terms-of-service',
	'https://www.wonderhood.in/pages/return-and-cancellation/':
		'https://www.thewonderlearn.com/return-and-cancellation',
	'https://www.wonderhood.in/pages/privacy-policy/':
		'https://www.thewonderlearn.com/privacy-policy',
	'https://www.wonderhood.in/pages/communication-skills-for-kids':
		'https://www.thewonderlearn.com/communication-skills-for-kids',
	'https://www.wonderhood.in/pages/online-games-for-kids':
		'https://www.thewonderlearn.com/online-games-for-kids'
};

export default App;
