import React from 'react';
import './TestimonialCardNew.scss';
import { imgUrl } from '../../../../assets/utils';

const TestimonialCardNew = ({ data }) => {
	return (
		<div className='testimonial-card flex items-center justify-center relative'>
			<img
				src={imgUrl('assetNew/test-back.webp')}
				alt=''
				className='absolute h-full w-full'
			/>
			<div className='content z-10 relative'>
				<img src={imgUrl('assetNew/quote.webp')} alt='' />
				<p>{data.message}</p>
				<div className='footer flex items-center absolute'>
					<div
						className='image-wrapper'
						style={{
							background: `url('${data.background}') center center/cover`
						}}></div>
					<div className='parent-info flex flex-col justify-between'>
						<p className='name'>{data.writter.split(',')[0]}</p>
						<p className='relation'>{data.writter.split(',')[1]}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TestimonialCardNew;
