import { css } from 'styled-components';

export const buttonColor1 = '#E2D868';
export const secHeaderColor = 'rgb(30 131 148)';
export const secHeaderFontSize = '36px';
export const secHeaderFontSizeMob = '18px';
export const fontFamily = 'Poppins';
export const primary = '#4bafc1';
export const secondary = '#f2f2f2';
export const yellow = '#E7DE78';

export const marginBtm155 = css`
	margin-bottom: 155px;

	@media (max-width: 480px) {
		margin-bottom: 39px;
	}
`;

export const heading = css`
	font-family: ${fontFamily};
	font-weight: 600;
	font-size: 36px;
	line-height: 54px;
	text-align: center;
	color: #1e8394;
	margin: 72px 0 58px;

	@media (max-width: 480px) {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 16px;
	}
`;

export const headPara = css`
	font-family: ${fontFamily};
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	width: 100%;
	margin-bottom: 42px;

	@media (max-width: 480px) {
		width: 310px;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 24px;
	}
`;

export const subHeading = css`
	font-family: ${fontFamily};
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	text-transform: capitalize;
	color: #1e8394;
	margin-bottom: 32px;

	@media (max-width: 480px) {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 16px;
	}
`;

export const subHeadPara = css`
	max-width: 1201px;
	width: 100%;
	font-family: ${fontFamily};
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 32px;

	@media (max-width: 480px) {
		width: 292px;
		font-size: 10px;
		line-height: 15px;
		margin-bottom: 16px;
	}
`;

export const FlexCenter = css`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
`;

export const pageHeaderDesktop = css`
	font-size: 48px;
	font-weight: 500;
	line-height: 72px;
	font-family: ${fontFamily};
`;

export const pageHeaderTab = css`
	font-size: 48px;
	font-weight: 500;
	line-height: 72px;
	text-align: center;
	font-family: ${fontFamily};
`;

export const pageHeaderMobile = css`
	font-size: 30px;
	font-weight: 500;
	line-height: 54px;
	text-align: center;
	font-family: ${fontFamily};
`;

export const pageSubHeaderDesktop = css`
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	font-family: ${fontFamily};
	margin: 10px 0;
	font-style: normal;
	letter-spacing: 0.05em;
	color: #000000;
	margin-bottom: 15px;
`;

export const pageSubHeaderMobile = css`
	text-align: center;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	font-family: ${fontFamily};
	font-weight: 600;
	margin: 10px 0;
	color: #000000;
`;

export const pageSubHeaderTab = css`
	font-size: 20px;
	line-height: 36px;
	text-align: center;
	font-family: ${fontFamily};
	font-weight: 600;
	margin: 10px 0;
	color: #000000;
`;

export const sectionHeaderDesktop = css`
	font-size: 36px;
	font-weight: 600;
	line-height: 54px;
	text-align: center;
	font-family: ${fontFamily};
`;

export const sectionHeaderMobile = css`
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	font-family: ${fontFamily};
`;

export const sectionHeaderTab = css`
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	font-family: ${fontFamily};
`;

export const ctaButton = css`
	width: 230px;
	height: 54px;
	font-size: 15px;
	font-family: ${fontFamily};
	background-color: ${buttonColor1};
	border: none;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);
	border-radius: 2px;
	color: #000;
	font-weight: 600;
	font-style: normal;
	line-height: 54px;
	letter-spacing: 0.5px;
	text-align: center;
	text-transform: uppercase;
`;

export const ctaButtonMobile = css`
	height: 40px;
	width: 192px;
	border-radius: 4px;
	background-color: ${buttonColor1};
	font-size: 14px;
	font-family: ${fontFamily};
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	color: #000;
	font-weight: 600;
	text-transform: uppercase;
`;

export const slickArrows = css`
	width: 30px;
	height: 30px;
	z-index: 9;
`;

export const slickArrowPrev = css`
	left: -3px;
	text-align: left;
`;

export const slickArrowNext = css`
	right: 0px;
	text-align: right;
`;
