export const DemoModel = {
	OPEN_DEMO_MODAL: 'OPEN_DEMO_MODAL',
	CLOSE_DEMO_MODAL: 'CLOSE_DEMO_MODAL'
};

export const GameModel = {
	OPEN_GAME_MODAL: 'OPEN_GAME_MODAL',
	CLOSE_GAME_MODAL: 'CLOSE_GAME_MODAL'
};

export const Window = {
	SET_WINDOW_SIZE: 'SET_WINDOW_SIZE',
	SET_WINDOW_TARGET: 'SET_WINDOW_TARGET'
};

export const userData = {
	USER_DATA_SUCCESS: 'USER_DATA_SUCCESS',
	USER_DATA_FAILURE: 'USER_DATA_FAILURE'
};

export const INITIAL_APP_STATE = {
	demoModal: '',
	gameModal: '',
	gameModalData: '',
	userData: {},
	window: {
		height: window.innerHeight,
		width: window.innerWidth,
		target: window.EventTarget
	},
	error: ''
};
