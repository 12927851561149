import { createContext, useEffect, useRef } from 'react';
import PricingPageBanner from '../Component/PricingPageBanner/PricingPageBanner';
import PricingGrid from '../Component/PricingUspGrid/PricingUspGrid';
import PricingPageCurriculum from '../Component/PricingPageCurriculum/PricingPageCurriculum';
import { curriculumInfo } from '../../values/Values';
import EnrollChild from '../Component/EnrollChild/EnrollChild';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
export const PricingRefContext = createContext();
export const EnrollRefContext = createContext();
export const ScrollIntoViewContext = createContext();
export const EnrollScrollContext = createContext();

const PlansAndPricing = () => {
	const pricingRef = useRef(null);
	const enrollRef = useRef(null);
	const [year, setYear] = useState('2-3');

	useEffect(() => {
		window.scrollTo({ behavior: 'smooth', top: 0 });
	}, []);

	const handleClick = () =>
			pricingRef.current?.scrollIntoView({ behavior: 'smooth' }),
		handleEnrollClick = () =>
			enrollRef.current?.scrollIntoView({ behavior: 'smooth' });

	const mixpanel = useMixpanel();

	useEffect(() => {
		mixpanel.track('Plans And Pricing Page');
	}, [mixpanel]);

	return (
		<>
			<Helmet>
				<title>Wonderlearn's Fees Structure for PreSchool – Wonderhood</title>
				<meta
					name='description'
					content='Best Fees Structure For Preschool Kids| We Provide Best Preschool Fee For Kids For 2 To 6 Years Old Boys And Girls.'
				/>
				<link rel='canonical' href='/plans-and-pricing' />
			</Helmet>
			<PricingRefContext.Provider value={pricingRef}>
				<ScrollIntoViewContext.Provider value={handleClick}>
					<EnrollRefContext.Provider value={enrollRef}>
						<EnrollScrollContext.Provider value={handleEnrollClick}>
							<PricingPageBanner />
							<EnrollChild
								year={year}
								setYear={setYear}
								initialProgramme='360'
								hasProgrammes
							/>
							<PricingPageCurriculum {...curriculumInfo[year]} />
							<PricingGrid />
						</EnrollScrollContext.Provider>
					</EnrollRefContext.Provider>
				</ScrollIntoViewContext.Provider>
			</PricingRefContext.Provider>
		</>
	);
};

export default PlansAndPricing;
