import { useState, useEffect } from 'react';
// import Values from '../../../values/Values';
import './SectionHeader.sass';

export default function SectionHeader(props) {
	const [sectionHeader, setHeaderState] = useState(null);

	useEffect(() => {
		setHeaderState(props.itemName);
	}, [props.itemName]);

	return (
		<div className='sectionHeader'>
			<h1>{sectionHeader}</h1>
		</div>
	);
}
