import './PricingUspGridElement.css';

function PricingUspGridElement({ num, section }) {
	const monthlyKitContent = section['contents'][num];
	const data = {
		img: monthlyKitContent['img'],
		heading: monthlyKitContent['heading'],
		text: monthlyKitContent['texts']
	};
	const { img, heading, text } = data;

	return (
		<div className='pricingUspGridElement'>
			<div className='pricingUspGridPic'>
				<img src={img} alt='' />
			</div>
			<div className='pricingUspGridText'>
				<h3>{heading}</h3>
				<p>{text}</p>
			</div>
		</div>
	);
}
export default PricingUspGridElement;
