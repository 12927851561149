import React from 'react';
import Slider from 'react-slick';
import { fontFamily } from '../../../_constants.styled';
import './PageBanner.scss';

export default function PageBanner({ bannerInfo, showModalToggle, points }) {
	const sliderImagesArr = bannerInfo['banner-slider-images'];

	const settings = {
		dots: false,
		infinite: true,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 2000,
		cssEase: 'linear',
		fade: true,
		responsive: [
			{
				breakpoint: 590,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			}
		]
	};

	const diffStyle = {
		color: '#646464',
		fontFamily: fontFamily,
		fontWeight: 500
	};

	return (
		<>
			{/* <div className='mobile-page-banner-heading-container'>
				<h1>
					{bannerInfo['banner-title-parts'].map((element, index) => (
						<span key={index}>{element}</span>
					))}
				</h1>
			</div> */}
			<div
				className='bannerContainer'
				style={{
					backgroundImage: `url(${bannerInfo['banner-backgroundImg-url']})`
				}}>
				<div className='bannerTextContainer'>
					<div className='textInfo'>
						<h1>
							{bannerInfo['banner-title-parts'].map((element, index) => (
								<span key={index}>{element}</span>
							))}
						</h1>
						<h2>{bannerInfo['banner-subHeading']}</h2>
						<ul>
							{bannerInfo['banner-points'].map((element, index) => (
								<li
									key={index}
									style={points ? { listStyle: 'disc' } : diffStyle}>
									{element}{' '}
								</li>
							))}
						</ul>
						<div className='button-wrapper'>
							<button className='button' onClick={showModalToggle}>
								{bannerInfo['banner-cta'].text}
							</button>
						</div>
					</div>
				</div>
				<Slider className='bannerSlider' {...settings}>
					{sliderImagesArr.map((element, i) => (
						<img src={element} alt='' key={`bannerSlider${i}`} />
					))}
				</Slider>
			</div>
		</>
	);
}
