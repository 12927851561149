import CurriculumCard from './CurriculumCard/CurriculumCard';
import SectionHeader from '../../Component/SectionHeader/SectionHeader';
import '../PricingPageCurriculum/PricingPageCurriculum.sass';

function PricingPageCurriculum({ subjectDetails, heading, age, subHeading }) {
	return (
		<div className='pricingPageCurriculumContainer'>
			<SectionHeader itemName={heading} />
			<div className='pricingPageSubHeaderContainer'>
				<p className='title'>{subHeading}</p>
				<p>({age} Years Old)</p>
			</div>
			<div className='curriculumCardsContainer'>
				{subjectDetails.map((subject, i) => (
					<CurriculumCard {...subject} key={`card-${i}`} />
				))}
			</div>
		</div>
	);
}

export default PricingPageCurriculum;
