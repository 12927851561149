export const FetchPlansPricing = {
	FETCH_PLANS_PRICING_START: 'FETCH_PLANS_PRICING_START',
	FETCH_PLANS_PRICING_SUCCESS: 'FETCH_PLANS_PRICING_SUCCESS',
	FETCH_PLANS_PRICING_FAILURE: 'FETCH_PLANS_PRICING_FAILURE'
};

export const INITIAL_SHOP_STATE = {
	plansPricing: null,
	error: null,
	isFetched: false
};
