import { useState, useEffect } from 'react';
import { CarouselContainer } from './Carousel.styled';

const Carousel = ({ images }) => {
	const [image, setImage] = useState(images[0]);

	useEffect(() => {
		if (!(images.length > 1)) return;
		const timeOut = setTimeout(() => {
			const newImg = images[images.indexOf(image) + 1]
				? images[images.indexOf(image) + 1]
				: images[0];
			setImage(newImg);
		}, 3000);

		return () => clearTimeout(timeOut);
	}, [image, images]);

	return (
		<CarouselContainer>
			<div className='img-wrapper'>
				<img src={image} alt='' />
			</div>
		</CarouselContainer>
	);
};

export default Carousel;
