import StepSwitch from './StepSwitch/StepSwitch';
import BannerImage from './BannerImage.png';
import ProgrammeChild from './ProgrammeChild/ProgrammeChild';
import { IoDiamond } from 'react-icons/io5';
import { useState, useMemo, useContext } from 'react';
import EnrollBtn from './EnrollBtn/EnrollBtn';
import { EnrollChildWrapper } from './EnrollChild.styled';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from './Carousel/Carousel';
import { formatNumber } from '../../../assets/utils';
import { useHistory } from 'react-router-dom';
import { addCartItem } from '../../../ReduxStore/Cart/Cart.Actions';
import { EnrollRefContext } from '../../PlansAndPricing/PlansAndPricing';
import { useMixpanel } from 'react-mixpanel-browser';

const EnrollChild = ({
	year,
	setYear,
	initialProgramme,
	hasProgrammes = false
}) => {
	const plansPricing = useSelector(state => state.shopReducer.plansPricing);
	const [month, setMonth] = useState('3');
	const [programme, setProgramme] = useState(initialProgramme);

	const toggleActive = value => setProgramme(value);
	const [images, setImages] = useState([BannerImage]);

	const handleYear = value => setYear(value);
	const handleMonth = value => setMonth(value);

	const enrollRef = useContext(EnrollRefContext);

	const history = useHistory();
	const mixpanel = useMixpanel();

	const product = useMemo(() => {
		if (!plansPricing)
			return {
				price: 0,
				compareAtPrice: 0,
				discount: 0,
				country: 'IN'
			};

		const variant = plansPricing[programme][year];
		setImages(variant.images);
		const { price, compareAtPrice } = variant[month];

		return {
			price,
			compareAtPrice,
			discount: Math.round(((compareAtPrice - price) / compareAtPrice) * 100),
			country: plansPricing.country
		};
	}, [month, plansPricing, programme, year]);

	const dispatch = useDispatch();

	const getSelectedItem = useMemo(
		() =>
			plansPricing
				? {
						...plansPricing[programme][year][month],
						description: plansPricing[programme][year].title,
						quantity: 1,
						duration: `${month} months / ${plansPricing.country}`
				  }
				: {},
		[month, plansPricing, programme, year]
	);

	const proceedCheckout = () => {
		mixpanel.track('Enrolled', {
			month,
			year,
			programme,
			...getSelectedItem
		});
		dispatch(addCartItem(getSelectedItem));
		history.push('/cart');
	};

	const programmeSection = (
		<>
			<p>2. Choose a programme.</p>
			<div className='programme-wrapper'>
				<ProgrammeChild
					title='Explorer'
					text='Placing the complete power of teaching in parents’ hands'
					keypoints={[
						'Monthly activity kit of learning toys',
						'Master access to our learning app',
						'Pre-recorded educational videos'
					]}
					toggleActive={toggleActive.bind(null, 'explorer')}
					isActive={programme === 'explorer'}
				/>
				<ProgrammeChild
					title='360'
					text='The most balanced & interactive early learning solution'
					titleIcon={<IoDiamond />}
					keypoints={[
						'Monthly activity kit of learning toys',
						'Master access to our learning app',
						'Live interactive online classes',
						'3 days a week'
					]}
					toggleActive={toggleActive.bind(null, '360')}
					isActive={programme === '360'}
				/>
			</div>
		</>
	);

	const feeSection = (
		<div className='fee-wrapper'>
			<h4>
				Course Fee :<br />
			</h4>
			<div>
				<p className='price'>
					{plansPricing?.currency} {formatNumber(product.price)}
				</p>
				<span className='compare-price'>
					{formatNumber(product.compareAtPrice)} {plansPricing?.currency}
				</span>
				<span>
					{' '}
					save {product.discount}%
					{!(product.country === 'IN') && (
						<>
							<br />
							<p>excludes shipping*</p>
						</>
					)}
				</span>
			</div>
		</div>
	);

	return (
		<EnrollChildWrapper
			country={product.country}
			diff={product.price !== product.compareAtPrice}>
			<div className='left'>
				<div>
					<p>1. Select the age of your child</p>
					<StepSwitch
						Val={['2-3', '3-4']}
						label='Years'
						handleClick={handleYear}
						selectedValue={year}
					/>
				</div>
				{hasProgrammes && <div ref={enrollRef}>{programmeSection}</div>}
				<div>
					<p>{hasProgrammes ? '3' : '2'}. Choose a Subscription plan.</p>
					<StepSwitch
						Val={['3', '6', '12']}
						label='Months'
						handleClick={handleMonth}
						selectedValue={month}
					/>
				</div>
				{!hasProgrammes && feeSection}
			</div>
			<div className='right'>
				<Carousel images={images} />
				{hasProgrammes && feeSection}
			</div>
			<EnrollBtn handleClick={proceedCheckout}>Enroll My Child</EnrollBtn>
		</EnrollChildWrapper>
	);
};

export default EnrollChild;
