import PageBanner from '../Component/PageBanner/PageBanner';
import UspGrid from '../Component/UspGrid/UspGrid';
import KeyPillar from '../Component/KeyPillars/KeyPillar';
import { useEffect } from 'react';
import EnrollChild from '../Component/EnrollChild/EnrollChild';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import {
	homeTestimonials,
	ExplorerPageBanner,
	expKeyPillarsInfo,
	uspGrid
} from '../../values/Values';
import { useModalToggler } from '../../assets/utils';
import Testimonials from '../Component/Testimonials/Testimonials';
import SectionHeader from '../Component/SectionHeader/SectionHeader';
import '../Component/VideoPlayer/VideoPlayer.scss';
import ReactPlayer from 'react-player';
export default function Explorer() {
	const [year, setYear] = useState('2-3');
	const uspContent = uspGrid['explorer-usp-info']['content'];
	const uspTotal = uspGrid['explorer-usp-info'];

	useEffect(() => {
		window.scrollTo({ behavior: 'smooth', top: 0 });
	}, []);

	const [showModalToggle] = useModalToggler();
	return (
		<>
			<Helmet>
				<title>Wonderlearn - Explorer</title>
				<meta
					name='description'
					content="Imagine, Learn, Play with Wonderhood - A Child's best and most comprehensive early learning partner."
				/>
				<link rel='canonical' href='/explorer' />
			</Helmet>
			<div className='overflow-hidden'>
				<PageBanner
					showModalToggle={showModalToggle}
					bannerInfo={ExplorerPageBanner}
				/>

				<UspGrid
					content={uspContent}
					total={uspTotal}
					elementMaxSize='320px'
					elementMinSize='30%'
				/>
				<a href='#pricing'>
					<button className='left-0 right-0 m-auto font-semibold text-lg flex justify-center capitalize w-[20rem] bg-secondary_1 pl-6 pr-6 pt-3 pb-3 rounded'>
						Talk to our experts
					</button>
				</a>
				<SectionHeader itemName='Our Plans' />
				<div className='m-auto h-96 max-w-[560px] min-w-0 w-full '>
					<div
						className='playerWrapper'
						style={{
							height: '400px',
							maxWidth: '560px',
							width: '100%'
						}}>
						<div className='mini-wrapper'>
							<ReactPlayer
								className='react-player'
								url='https://www.youtube.com/watch?v=F9vTP5soR8U&t=9s'
								controls={true}
								muted={true}
								playing={true}
								loop
								width='560px'
								height='400px'
								wrapper='div'
							/>
						</div>
					</div>
				</div>
				<div id='pricing'>
					<EnrollChild
						year={year}
						setYear={setYear}
						initialProgramme='explorer'
					/>
				</div>
				<KeyPillar
					pillarsInfoContainer={expKeyPillarsInfo}
					heading=' The most sought after early learning program in India'
					subHeading='Experience the combined power of toys, teachers & technology'
				/>
				<Testimonials
					heading='What Our Happy Customers Say?'
					testimonials={homeTestimonials}
				/>
			</div>
		</>
	);
}
