import styled from 'styled-components';

export const CarouselContainer = styled.div`
	margin-bottom: 100px;

	.img-wrapper {
		position: relative;
		width: 454px;
		height: 500px;

		img {
			position: absolute;
			object-fit: contain;
			width: 100%;
			height: 100%;
			transition: 0.5s;
		}

		@media (max-width: 480px) {
			height: 350px;
			width: 350px;
		}
	}
`;
