const toggleButton = ({ interval, label, funcTocall, active }) => (
	<input
		className={`button ${active ? 'active' : ''}`}
		type='submit'
		onClick={funcTocall.bind(null, interval)}
		value={`${interval} ${label}`}
	/>
);

export default toggleButton;
