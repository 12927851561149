import React from 'react';
import { imgUrl } from '../../../assets/utils';
import './TestimonialNew.scss';
import TestimonialCardNew from './TestimonialCardNew/TestimonialCardNew';
import CCard from '../../../ComponentsNew/CCarousel/CCard';

const TestimonialNew = ({ heading, testimonials }) => {
	return (
		<div
			className='testimonial-container'
			style={{
				background: `url('${imgUrl(
					'assetNew/testimonial-back.webp'
				)}') center center/cover no-repeat`
			}}>
			<h1 className='text-white text-center'>{heading}</h1>
			<div className='mob-view'>
				<CCard RenderCard={TestimonialCardNew} Data={testimonials} />
			</div>
			<div className='testimonial-wrapper flex justify-between'>
				<div className='flex flex-col justify-between'>
					<TestimonialCardNew data={testimonials[0]} />
					<TestimonialCardNew data={testimonials[1]} />
				</div>
				<div className='flex flex-col justify-between'>
					<TestimonialCardNew data={testimonials[2]} />
					<TestimonialCardNew data={testimonials[3]} />
				</div>
				<div className='flex flex-col justify-between'>
					<TestimonialCardNew data={testimonials[4]} />
					<TestimonialCardNew data={testimonials[1]} />
				</div>
			</div>
		</div>
	);
};

export default TestimonialNew;
