import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
	FetchPlansPricingFailure,
	FetchPlansPricingSuccess
} from './Shop.Actions';
import { FetchPlansPricing } from './Shop.Constants';
import Client from 'shopify-buy';
import axios from 'axios';
import { updateCurrency } from '../Cart/Cart.Actions';

export function* fetchPlansPricingStarter() {
	try {
		const client = Client.buildClient({
			domain: process.env.REACT_APP_DOMAIN,
			storefrontAccessToken: process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN
		});

		const collection = yield client.collection.fetchWithProducts(
			process.env.REACT_APP_PLANS_PRICING_COLLECTION_ID
		);
		let data = {};

		try {
			data = yield axios.get('https://ipapi.co/json/');
			data = data.data || {};
		} catch (error) {
			console.error(
				"Failed to locate user's country, setting default country to IN"
			);
		}

		const country = data.country_code || 'IN';
		const currency =
			country === 'IN' ? 'INR' : data.country === 'AE' ? 'AED' : 'USD';

		const OSTypes = {
			'Windows NT 10.0': 'Windows',
			'Windows NT 6.3': 'Windows',
			'Windows NT 6.2': 'Windows',
			'Windows NT 6.1': 'Windows',
			'Windows NT 6.0': 'Windows',
			'Windows NT 5.1': 'Windows',
			'Windows NT 5.0': 'Windows',
			Mac: 'Mac',
			X11: 'Unix',
			Linux: 'Linux',
			Android: 'Android',
			webOS: 'webOS',
			iPhone: 'iOS',
			iPad: 'iOS',
			iPod: 'iOS',
			IEMobile: 'Windows',
			'IEMobile 7': 'Windows',
			'IEMobile 6.5': 'Windows',
			'IEMobile 6.1': 'Windows',
			'IEMobile 6': 'Windows',
			'IEMobile 5.5': 'Windows',
			'IEMobile 5.1': 'Windows',
			'IEMobile 5': 'Windows',
			'IEMobile 4.9': 'Windows',
			'IEMobile 4.0': 'Windows',
			'Windows Phone': 'Windows'
		};

		let OS;

		for (const key in OSTypes) {
			if (OS) break;
			if (navigator.userAgent.match(key)) OS = OSTypes[key];
		}

		if (!OS) OS = 'Unknown';

		const Device_Type = getDeviceType();

		const userData = {
			country: data.country_code || 'IN',
			Country: data.country_name || '',
			City: data.city || '',
			State: data.region || '',
			Zip_Code: data.postal || '',
			currency,
			OS,
			IP: data.ip || '',
			Device_Type,
			Screen_Width: window.innerWidth,
			Screen_Height: window.innerHeight
		};

		collection.userData = userData;

		yield put(FetchPlansPricingSuccess(collection));
		yield put(updateCurrency(currency));
	} catch (error) {
		yield put(FetchPlansPricingFailure(error));
	}
}

function getDeviceType() {
	const width = window.innerWidth;
	if (width >= 1024) return 'desktop';
	else if (width >= 768) return 'tablet';
	else return 'mobile';
}

export function* fetchPlansPricingStart() {
	yield takeLatest(
		FetchPlansPricing.FETCH_PLANS_PRICING_START,
		fetchPlansPricingStarter
	);
}

export default function* shopSagas() {
	yield all([call(fetchPlansPricingStart)]);
}
