import { useState } from 'react';
import TestimonialCard from './TestimonialCard/TestimonialCard';
import SectionHeader from '../SectionHeader/SectionHeader';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.scss';

const Testimonials = ({ heading, testimonials, RenderCard = null }) => {
	const [imageIndex, setImageIndex] = useState(0);
	const settings = {
		centerMode: true,
		slidesToScroll: 1,
		slidesToShow: 3,
		autoplay: true,
		autoplaySpeed: 2500,
		infinite: true,
		centerPadding: '15px',
		beforeChange: (current, next) => setImageIndex(next),
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 1,
					centerPadding: '5px'
				}
			}
		]
	};

	return (
		<div className='testimonials-container'>
			<SectionHeader itemName={heading} />
			<div className='testimonials-wrapper'>
				<Slider {...settings}>
					{testimonials.map((e, index) => (
						<div
							className={index === imageIndex ? 'activeSlide' : 'slide'}
							key={`testimonial${index}`}
						>
							{RenderCard ? <RenderCard {...e} /> : <TestimonialCard {...e} />}
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default Testimonials;
