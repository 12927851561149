import styled, { css } from 'styled-components';
import { FlexCenter, fontFamily, primary } from '../../../../_constants.styled';

const ActiveStyle = css`
	background: ${primary};
	color: white;
`;

export const ProgrammeChildWrapper = styled.div`
	max-width: 249px;
	width: 100%;
	height: 328px;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	${FlexCenter}
	margin-bottom: 30px;
	background: #f8f8f8;
	flex-direction: column;

	.title {
		font-family: ${fontFamily};
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		margin: 10px;
	}

	div {
		${FlexCenter}
		cursor: pointer;

		.top {
			background: #e8e8e8;
			max-width: 249px;
			width: 100%;
			height: 68.62px;
			border-radius: 5px;
			${FlexCenter}
			font-size: 22px;
			cursor: pointer;
			transition: all 0.5s ease-in-out;

			${({ isActive }) => (isActive ? ActiveStyle : '')}
		}

		ul {
			padding: 15px;
			width: 100%;
			font-size: 10px;

			li {
				display: flex;
				gap: 5px;
				font-family: 'Poppins';
				font-weight: 500;
				list-style: none;
				padding: 5px;
			}
		}
	}

	span {
		margin-top: auto;
		text-align: center;
		color: #19a0b9;
		font-size: 14px;
		font-family: 'Poppins';
		font-weight: 500;
		cursor: pointer;
		margin-bottom: 15px;
	}
`;
